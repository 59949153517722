import { Menu, Content, FormContainer, Footer } from 'layout/shared';
import styled from 'styled-components/macro';
import cameraImageUrl from 'asset/camera.png';

const LeftBottomBlock = styled.div`
  top: calc(100vh - 307px);
  width: 452px;
  position: sticky;
  display: flex;
  z-index: 1;
  ${(props) => props.theme.media.laptop_M} {
    width: 315px;
    top: calc(100vh - 214px);
  }
  ${(props) => props.theme.media.tablet_L} {
    width: 250px;
    top: calc(100vh - 170px);
  }
  ${(props) => props.theme.media.tablet_S} {
    display: none;
  }

  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
`;
const LittleCube = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  ${(props) => props.theme.media.laptop_M} {
    width: 16.5px;
    height: 16.5px;
  }
  ${(props) => props.theme.media.tablet_L} {
    width: 15px;
    height: 15px;
  }
`;
const LittleCubeContainer = styled.div`
  position: absolute;
  right: 286px;
  bottom: 0;
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  ${(props) => props.theme.media.laptop_M} {
    right: 200px;
  }
  ${(props) => props.theme.media.tablet_L} {
    right: 155px;
  }

  ${LittleCube} {
    margin-left: -1px;
    margin-top: -1px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  margin: 80px auto 0;
  ${(props) => props.theme.media.tablet_S} {
    flex-direction: column;
  }
`;
const ProgressContainer = styled.div`
  padding-top: 58px;
  flex: 1 0.5 auto;
  width: 400px;
  color: ${(props) => props.theme.color.bluegray000};
  ${(props) => props.theme.media.laptop_L} {
    width: 300px;
  }
  ${(props) => props.theme.media.laptop_M} {
    width: 275px;
  }
  ${(props) => props.theme.media.tablet_L} {
    width: 200px;
  }

  ${(props) => props.theme.media.tablet_M} {
    width: 180px;
  }
  ${(props) => props.theme.media.tablet_S} {
    position: sticky;
    inset: 80px auto auto auto;
    width: 100%;
    margin-bottom: 0;
    padding-top: 13px;
    background: ${(props) => props.theme.color.bluegray900};
    z-index: 4;
  }
`;

function CameraLayout(props) {
  const { progressBlock, children } = props;
  return (
    <>
      <Menu />
      <Content>
        <Wrapper>
          <ProgressContainer>
            {progressBlock}
            <LeftBottomBlock>
              <LittleCubeContainer>
                {Array(560)
                  .fill()
                  .map((_, i) => (
                    <LittleCube key={i} />
                  ))}
              </LittleCubeContainer>
              <img src={cameraImageUrl} alt="Logo" />
            </LeftBottomBlock>
          </ProgressContainer>
          <FormContainer>{children}</FormContainer>
        </Wrapper>
      </Content>
      <Footer />
    </>
  );
}

export default CameraLayout;
