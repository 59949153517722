import LoginLayout from 'layout/LoginLayout';
import LoginForm from 'app/Form/Login';

function LoginPage() {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  );
}

export default LoginPage;
