import Portal from './Portal';
import styled, { keyframes } from 'styled-components/macro';
import useTimeoutValue from 'hook/useTimeoutValue';

const hideMask = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
`;

const showMask = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
`;

const Mask = styled.div`
  position: fixed;
  inset: 0 auto auto 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.color.bluegray900};
  opacity: 0.4;
  z-index: 98;
  animation: ${(props) => (props.$isOpen ? showMask : hideMask)}
    ${(props) => props.$animationDuration}ms ease-in-out forwards;
`;

export const hideDialog = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
`;

export const showDialog = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const ModalWrapper = styled.div`
  max-width: 100%;
  position: fixed;
  inset: 100px auto 30px 50%;
  transform: translateX(-50%);
  z-index: 99;
  animation: ${(props) => (props.$isOpen ? showDialog : hideDialog)}
    ${(props) => props.$animationDuration}ms ease-in-out forwards;
  ${(props) => props.theme.media.tablet_M} {
    inset: 50px auto 50px 50%;
  }
`;

const Modal = ({ isOpen, onClose, animationDuration, children, hasMask }) => {
  const isShowDOM = useTimeoutValue(isOpen, {
    delay: animationDuration,
    exceptionList: [true],
  });

  return (
    isShowDOM && (
      <Portal>
        {hasMask && (
          <Mask
            $isOpen={isOpen}
            $animationDuration={animationDuration}
            onClick={onClose}
          />
        )}

        <ModalWrapper $isOpen={isOpen}>{children}</ModalWrapper>
      </Portal>
    )
  );
};

Modal.defaultProps = {
  isOpen: false,
  hasMask: true,
  animationDuration: 200,
  onClose: () => {},
};

export default Modal;
