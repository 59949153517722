import { useState } from 'react';
import styled from 'styled-components/macro';
import Input from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

const Clickable = styled.div`
  cursor: pointer;
`;

function PasswordInput(props) {
  const [canSee, setCanSee] = useState(false);
  return (
    <Input
      {...props}
      type={canSee ? 'text' : 'password'}
      suffix={
        <Clickable
          onClick={() => {
            setCanSee(!canSee);
          }}
        >
          {canSee ? (
            <FontAwesomeIcon icon={['fas', 'eye']} />
          ) : (
            <FontAwesomeIcon icon={['fas', 'eye-slash']} />
          )}
        </Clickable>
      }
    />
  );
}
PasswordInput.defaultProps = {};

export default memo(PasswordInput);
