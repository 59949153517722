import FormControl from 'component/FormControl';
import Input, { TextField } from 'component/Input/Input';
import { Button } from 'component/Button';
import { StyleRadio } from 'component/Input/Radio/Radio';
import PasswordInput from 'component/Input/PasswordInput';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useMutation } from 'react-query';
import storageKey from 'constant/storageKey';
import { writeStorage } from '@rehooks/local-storage';
import { Error } from 'component/Typography';
import { useMedia } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RegisterLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.color.bluegray400};
`;
const ForgetPasswordLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.color.primary600};
`;
const LinkSet = styled.div`
  display: flex;
  gap: 48px;
  ${(props) => props.theme.media.laptop_L} {
    flex-direction: column;
    gap: 15px;
  }
  ${(props) => props.theme.media.mobile_L} {
    width: 100%;
    align-items: center;
  }
`;
const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  ${TextField} {
  }
  ${StyleRadio} {
    color: ${(props) => props.theme.color.bluegray050};
  }
  ${Button} {
    display: block;
  }
`;
const Remind = styled.ul`
  display: flex;
  padding-right: 8px;
  color: white;
  font-size: 14px;
  line-height: 1.4;
  text-shadow: 1px 0.5px 1px black;
  position: absolute;
  top: 105%;
  z-index: 3;
  ${(props) => props.theme.media.desktop} {
    font-size: calc(1vw * (16 / 16));
  }
  ${(props) => props.theme.media.laptop_L} {
    left: 30%;
    top: 62%;
  }
  ${(props) => props.theme.media.tablet_L} {
    display: none;
  }
  svg {
    margin-top: 1.5%;
    margin-right: 4px;
    font-size: 12px;
    filter: drop-shadow(1px 0.5px 1px black);
    ${(props) => props.theme.media.desktop} {
      font-size: calc(1vw * (16 / 16));
    }
    ${(props) => props.theme.media.laptop_M} {
      margin-top: 0.5%;
    }
  }
`;
function LoginForm(props) {
  const { t } = useTranslation();
  const isTablet_L = useMedia('(max-width: 768px)');
  const loginMutation = useMutation(api.login, {
    onSuccess: (res) => {
      if (!res.data.status) return;
      writeStorage(storageKey.USER, {
        token: res.data.token,
        ...res.data.user,
      });
    },
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t('common.validation.required')),
      password: Yup.string().required(t('common.validation.required')),
    }),
    onSubmit: (values) => {
      loginMutation.mutate(values);
    },
  });

  const { touched, errors } = formik;

  return (
    <StyledLoginForm onSubmit={formik.handleSubmit}>
      <FormControl
        label={t('common.memberMail')}
        isError={!!(touched.email && errors.email)}
        errorMessage={errors.email}
      >
        <Input id="email" {...formik.getFieldProps('email')} />
      </FormControl>
      <FormControl
        label={t('common.password')}
        isError={!!(touched.password && errors.password)}
        errorMessage={errors.password}
      >
        <PasswordInput id="password" {...formik.getFieldProps('password')} />
      </FormControl>
      {loginMutation.isError && <Error>{loginMutation.error}</Error>}
      <Button type="submit" size={isTablet_L ? 'large' : 'small'}>
        {t('common.login')}
      </Button>
      <LinkSet>
        <RegisterLink to="/register">{t('common.register')}</RegisterLink>
        <ForgetPasswordLink to="/forgot">
          {t('common.forgetPassword')}
        </ForgetPasswordLink>
      </LinkSet>
      <Remind>
        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
        <li style={{ whiteSpace: 'pre-wrap' }}>{t('loginPage.remind')}</li>
      </Remind>
    </StyledLoginForm>
  );
}

export default LoginForm;
