import styled, { css } from 'styled-components/macro';

const colorPrimaryStyle = css`
  color: ${(props) => props.theme.color.bluegray000};
  background: ${(props) => props.theme.color.primary600};
`;
const colorSecondaryStyle = css`
  color: ${(props) => props.theme.color.bluegray900};
  background: ${(props) => props.theme.color.bluegray000};
`;

const colorStyle = {
  primary: colorPrimaryStyle,
  secondary: colorSecondaryStyle,
};

const sizeLargeStyle = css`
  width: 100%;
  padding: 12px 30px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 20%;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 18px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 16px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 14px;
  }
`;
const sizeMiddleStyle = css`
  padding: 22px 55px;
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 18px;
    padding: 16px 42px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 16px;
    padding: 12px 38px;
  }
  ${(props) => props.theme.media.mobile_L} {
    padding: 10px 21px;
  }
  ${(props) => props.theme.media.mobile_S} {
    font-size: 14px;
    padding: 8px 18px;
  }
`;
const sizeSmallStyle = css`
  padding: 8px 16px;
  font-size: 16px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_L} {
    padding: 6px 14px;
    font-size: 12px;
  }
`;

const sizeStyle = {
  small: sizeSmallStyle,
  middle: sizeMiddleStyle,
  large: sizeLargeStyle,
};

const disabledStyle = css`
  position: relative;
  pointer-events: none;
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 0 0 0;
    background: ${(props) => props.theme.color.bluegray000};
    opacity: 0.5;
  }
`;

const fullWidthStyle = css`
  width: 100%;
`;

export const Button = styled.button`
  position: relative;
  box-shadow: 0 6px 10px rgba(67, 67, 75, 0.1);
  transition: 0.3s;
  border: none;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearence: none;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }

  ${(props) => colorStyle[props.color] || colorStyle.primary};
  ${(props) => sizeStyle[props.size] || sizeStyle.small};
  ${(props) => (props.disabled ? disabledStyle : null)};
  ${(props) => (props.fullWidth ? fullWidthStyle : null)};
`;

Button.defaultProps = {
  color: 'primary',
  size: 'small',
  disabled: false,
};
