import styled from 'styled-components/macro';

const Content = styled.div`
  max-width: ${(props) => props.theme.widthBoundary};
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  flex: auto;
  ${(props) => props.theme.media.laptop_M} {
    padding: 0 20px 20px 20px;
  }
  ${(props) => props.theme.media.tablet_S} {
    padding: 0 10px 20px 10px;
  }
`;

export { Content };
