import styled from 'styled-components/macro';

export const Description = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: ${(props) =>
    props.light
      ? props.theme.color.bluegray500
      : props.theme.color.bluegray900};
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_L} {
    font-size: 12px;
  }
`;
