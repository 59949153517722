import styled from 'styled-components/macro';

export const FormHeading = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: ${(props) => props.theme.color.bluegray900};
  ${(props) => props.theme.media.laptop_M} {
    font-size: 20px;
  }
  ${(props) => props.theme.media.mobile_L} {
    font-size: 16px;
    line-height: 28px;
  }
`;
