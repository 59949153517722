import styled, { css } from 'styled-components/macro';
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useMutation } from 'react-query';
import api from 'api';
import Popup from 'app/Form/component/Popup';
import Upload from 'component/Input/ContractUpload';
import storageKey from 'constant/storageKey';

const unsentWrapperStyle = css`
  background: ${(props) => props.theme.color.bluegray300};
`;
const successWrapperStyle = css`
  background: ${(props) => props.theme.color.success};
`;
const insufficientWrapperStyle = css`
  background: ${(props) => props.theme.color.warning};
`;
const notVerifyWrapperStyle = css`
  background: ${(props) => props.theme.color.primary600};
`;

const wrapperStyle = {
  accepting: unsentWrapperStyle,
  success: unsentWrapperStyle,
  insufficient: unsentWrapperStyle,
  not_verify: unsentWrapperStyle,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 20px 16px 36px;
  box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.color.bluegray200};
  border-radius: 2px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 auto 0 0;
    width: 6px;
    ${(props) => wrapperStyle[props.type]};
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 16px 20px 16px 30px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 16px 20px 16px 25px;
  }
`;

const StatusGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const unsentStatusStyle = css`
  color: ${(props) => props.theme.color.bluegray000};
  background: ${(props) => props.theme.color.bluegray300};
`;

const successBtnStyle = css`
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  padding: 6px;
  background: ${(props) => props.theme.color.success};
`;

const insufficientBtnStyle = css`
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  padding: 6px;
  background: ${(props) => props.theme.color.warning};
`;

const notVerifyBtnStyle = css`
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
  padding: 6px;
  background: ${(props) => props.theme.color.primary600};
`;

const statusStyle = {
  accepting: unsentStatusStyle,
  pass: successBtnStyle,
  success: successBtnStyle,
  insufficient: insufficientBtnStyle,
  not_verify: notVerifyBtnStyle,
};

const statusStyleTitle = {
  accepting: unsentStatusStyle,
  pass: unsentStatusStyle,
  success: unsentStatusStyle,
  insufficient: unsentStatusStyle,
  not_verify: unsentStatusStyle,
};

const Status = styled.span`
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 2px;
  ${(props) => statusStyle[props.type]};
  span {
    ${(props) => props.theme.media.laptop_M} {
      font-size: 14px;
    }
    ${(props) => props.theme.media.tablet_L} {
      font-size: 12px;
    }
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 2px 10px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 0px 10px;
  }
`;

const Statuses = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StatusTitle = styled.span`
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 2px;
  ${(props) => statusStyleTitle[props.type]};
  span {
    ${(props) => props.theme.media.laptop_M} {
      font-size: 14px;
    }
    ${(props) => props.theme.media.tablet_L} {
      font-size: 12px;
    }
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 2px 10px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 0px 10px;
  }
`;

const Accepting = styled.div`
  cursor: pointer;
  position: relative;
  margin: 0 -20px -16px -36px;
  left: 0;
  background: #d5d5d8;
  text-align: center;
  font-size: 15px;
  color: #121314;
  padding: 10px;
  background: #eceaea;
  border: 1px solid #d5d5d8;
  ${(props) => props.theme.media.laptop_M} {
    margin: 0 -20px -16px -30px;
  }
  ${(props) => props.theme.media.tablet_L} {
    margin: 0 -20px -16px -25px;
  }
`;
const actionStyle = {};

export const Action = styled.span`
  padding: 0;
  color: ${(props) => props.theme.color.bluegray600};
  font-size: 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    color: ${(props) => props.theme.color.bluegray900};
  }
  svg {
    margin-left: 8px;
  }
  ${(props) => actionStyle[props.type]}
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 12px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 14px;
`;

const Title = styled.h3`
  margin: 10px 0;
  color: ${(props) => props.theme.color.bluegray900};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 16px;
  }
`;

const SchemeGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  ${(props) => props.theme.media.tablet_M} {
    gap: 3px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

// const Scheme = styled.span`
//   color: ${(props) => props.theme.color.bluegray900};
//   font-size: 15px;
//   line-height: 21px;
//   ${(props) => props.theme.media.laptop_M} {
//     font-size: 13px;
//   }
//   ${(props) => props.theme.media.tablet_L} {
//     font-size: 12px;
//   }
// `;

const Time = styled.span`
  color: ${(props) => props.theme.color.bluegray500};
  font-size: 14px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 12px;
  }
  ${(props) => props.theme.media.tablet_S} {
    position: absolute;
    inset: auto 20px auto auto;
  }
`;

const ActionText = styled.span`
  color: ${(props) => props.theme.color.bluegray900};
  cursor: default;
`;
// const NumberText = styled.div`
//   color: ${(props) => props.theme.color.bluegray500};
//   ${(props) => props.theme.media.laptop_M} {
//     display: none;
//   }
// `;
const InfoGroup = styled.div`
  position: relative;
  margin: 15px -20px -16px -36px;
  height: auto;
  background: #ffffff;
  color: #8f8e8e;
  border: 1px solid #d5d5d8;
  ${(props) => props.theme.media.laptop_L} {
    display: none;
  }
  ${(props) => props.theme.media.laptop_M} {
    margin: 15px -20px -16px -30px;
  }
  ${(props) => props.theme.media.tablet_L} {
    margin: 15px -20px -16px -25px;
  }
`;
const InfoTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 1fr 2fr 2fr 2fr;
  font-size: 12px;
  font-size: 12px;
  line-height: 17px;
  color: #8f8e8e;
  text-align: center;
`;
const InfoTitleGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 1fr 2fr 2fr 2fr;
  font-size: 12px;
  grid-column: 1 / 8;
  grid-template-rows: 18px;
  border-bottom: 2px solid #bbb7b7;
  padding: 5px 0;
`;

const InfoContentGroup = styled.div`
  display: grid;
  grid-column: 1 / 8;
  grid-template-columns: 1fr 2fr 4fr 1fr 2fr 2fr 2fr;
  mix-blend-mode: multiply;
  border-top: 5px solid #f2f1f1;
  border-bottom: 2px solid #bbb7b7;
`;

const InfoItemOne = styled.div`
  background: #ececec;
  mix-blend-mode: multiply;
  opacity: 0.87;
  grid-row: span 3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #969696;
`;

const InfoItemTwoGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr 1fr 2fr 2fr 2fr;
  grid-column: 2 / 8;
  border-bottom: 1px solid #cbcbcb;
  padding: 20px 0;
  color: #000000;
  font-size: 12px;
`;

const InfoItemThreeGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column: 2 / 8;
`;

const ItemThreeTitle = styled.div`
  background: #ececec;
  border-right: 0.5px solid #bdbdbd;
  border-left: 0.5px solid #bdbdbd;
  padding: 15px 0px;
  font-size: 13px;
  color: #9c9c9c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemThreeContent = styled.div`
  color: #555555;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemThreeBtn = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 9px 25px;
  ${(props) => props.theme.media.laptop_L} {
    padding: 9px 10px 9px 20px;
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 10px;
  }
`;

const ItemFourGroup = styled.div`
  grid-column: 2 / 8;
  background: #f2f2f2;
`;

const ItemFourTitle = styled.div`
  background: #d8d8d8;
  font-size: 13px;
  color: #4f4f4f;
  padding: 8px 0;
`;

const ItemFourContent = styled.ul`
  text-align: left;
  list-style: auto;
  padding: 14px 0px 14px 20px;
`;

const UploadList = styled.li`
  font-size: 12px;
  color: #7b7b7b;
  line-height: 25px;
`;

const UploadBtn = styled.button`
  border: 1px solid #6a6868;
  border-radius: 6px;
  font-size: 12px;
  line-height: 17px;
  color: #6a6868;
  padding: 5px 10px;
  cursor: pointer;
  svg {
    margin-left: 8px;
  }
`;

const MobileInfoGroup = styled.div`
  position: relative;
  margin: 15px -20px -16px -36px;
  background: #ffffff;
  color: #8f8e8e;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${(props) => props.theme.media.laptop_M} {
    margin: 15px -20px -16px -30px;
  }
  ${(props) => props.theme.media.tablet_L} {
    margin: 15px -20px -16px -25px;
  }
`;

const MobileTable = styled.div`
  ${(props) => props.theme.media.laptop_L} {
    border: 1px solid #d5d5d8;
    display: grid;
    grid-template-columns: 1fr 12fr;
    border-radius: 2px;
  }
`;

const MobileItemOne = styled.div`
  grid-row: 1 / 4;
  background: #828282;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

const MobileItemTwo = styled.div`
  padding: 12px 12px 0 12px;
`;

const MobileItemTwoTable = styled.table`
  width: 100%;
`;

const TableTitile = styled.td`
  width: 90px;
  font-size: 10px;
  color: #8f8e8e;
`;

const TableContent = styled.td`
  padding-bottom: 20px;
  font-size: 12px;
  color: #000000;
  line-height: 17px;
`;

const MobileItemThree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  ${(props) => props.theme.media.tablet_M} {
    grid-template-columns: 1fr;
  }
`;

const MoblieItemThreeTitle = styled.div`
  font-size: 10px;
  padding: 10px 0;
  background: #ececec;
  border: 0.5px solid #bdbdbd;
  color: #414141;
`;

const MobileItemThreeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 17px 0;
  color: #555555;
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  ${(props) => props.theme.media.laptop_L} {
    height: 65px;
    padding: 14px 0;
  }
`;

const MobileThreeBtn = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 6px 0;
  font-size: 12px;
  color: #555555;
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  ${(props) => props.theme.media.laptop_L} {
    height: 65px;
    padding: 14px 0;
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 17px 0;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 20px 0 17px 0;
    gap: 15px;
  }
`;

const PopupTitle = styled.div`
  background: #000000;
  color: #ffffff;
  text-align: center;
  padding: 14px 0 8px 0;
`;

const PopupContent = styled.div`
  padding: 20px 25px 30px 25px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const MobileFourItemTitle = styled.div`
  padding: 10px 0;
  text-align: center;
  font-size: 10px;
  color: #414141;
  background: #ececec;
  border: 0.5px solid #bdbdbd;
`;

const MobileList = styled.ul`
  font-size: 10px;
  color: #7b7b7b;
  list-style: auto;
  margin-left: 30px;
  li {
    margin: 12px 0;
  }
`;
// const StatusText = styled.span``;
function StatusText(props) {
  const { t } = useTranslation();
  const StatusTextMap = {
    success: t('memberCenter.performance.success'),
    pass: t('memberCenter.performance.success'),
    insufficient: t('memberCenter.performance.insufficient'),
    not_verify: t('memberCenter.performance.notVerify'),
    accepting: t('memberCenter.performance.acceptingStatus'),
  };

  const statusText = StatusTextMap[props.type];

  return <span>{statusText}</span>;
}

const getUserToken = () => {
  const userInfo = localStorage.getItem(storageKey.USER);
  const user = !!userInfo ? JSON.parse(userInfo) : {};
  return user.token || '';
};
// const getLocale = () => {
//   const locale = localStorage.getItem(storageKey.LOCALE);
//   return locale === 'en-US' ? 'en' : locale;
// };

const ContractCard = ({
  type,
  id,
  title,
  time,
  url,
  plan,
  plan_option,
  is_notify,
  period,
}) => {
  const token = getUserToken();
  const { t } = useTranslation();
  const isLaptopL = useMedia('(max-width: 1280px)');
  const fileContractMutation = useMutation(api.deleteContractFile);

  const svgIconDown = {
    position: 'absolute',
    fontSize: '20px',
    marginTop: '-6px',
    right: '13px',
  };

  const svgIconUp = {
    position: 'absolute',
    fontSize: '20px',
    right: '13px',
  };

  const notify = {
    position: 'absolute',
    top: '3px',
  };

  const trash = {
    width: '0.75em;',
  };

  const [introPopup, setIntroPopup] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [btnPopup, setBtnPopup] = useState(false);
  const [showMini, setShowMini] = useState(false);
  const [divHeight, setDivHeight] = useState(0);
  const parentRef = useRef();

  useEffect(() => {
    if (parentRef.current) {
      setDivHeight(parentRef.current.scrollHeight);
      window.addEventListener('resize', () => {
        setDivHeight(parentRef.current.scrollHeight);
      });
    } else {
      setDivHeight(0);
    }
  }, [divHeight]);

  const showStyle = {
    overflow: 'hidden',
    height: showMini ? divHeight + 'px' : '0px',
    transition: '1s',
  };
  const handleChange = () => {
    setShowMini((prev) => !prev);
  };

  const handleClick = (event) => {
    setBtnPopup(true);
    const id = event.target.dataset.id;
    const key = event.target.dataset.key;
    localStorage.setItem('ContractId', id);
    localStorage.setItem('Period', key);
  };

  const handleDelete = (event) => {
    const contractId =
      event.target.parentElement.parentElement.parentElement.dataset.id;
    const fileId =
      event.target.parentElement.parentElement.parentElement.dataset.key;
    const data = {
      token: token,
      contract_id: contractId,
      file_id: fileId,
    };
    fileContractMutation.mutateAsync(data).then((res) => {
      event.target.parentElement.parentElement.parentElement.remove();
    });
  };

  const periodList = period;
  const number = [];
  const mobileNumber = [];

  periodList.forEach((data) => {
    number.push(
      <InfoContentGroup>
        <InfoItemOne>{data.period}</InfoItemOne>
        <InfoItemTwoGroup>
          <div>{data.deadline}</div>
          <div style={{ textAlign: 'left' }}>{data.des}</div>
          <div>{data.currency}</div>
          <div>{data.payables}</div>
          <div>{data.receivables}</div>
          {data.is_invoice === true ? (
            <div>{t('memberCenter.performance.isInvoice')}</div>
          ) : (
            <div>{t('memberCenter.performance.isNotInvoice')}</div>
          )}
        </InfoItemTwoGroup>
        <InfoItemThreeGroup>
          <ItemThreeTitle>
            {t('memberCenter.performance.document')}
          </ItemThreeTitle>
          <ItemThreeContent>
            {data.file_required === '0' ||
            data.status === 'accepting' ||
            data.status === 'pass' ? (
              <>-</>
            ) : (
              <UploadBtn
                onClick={handleClick}
                data-id={id}
                data-key={data.period}
              >
                {t('memberCenter.performance.upload')}
                <FontAwesomeIcon icon={['fas', 'upload']} />
              </UploadBtn>
            )}
          </ItemThreeContent>
          <ItemThreeTitle>
            {t('memberCenter.performance.status')}
          </ItemThreeTitle>
          <ItemThreeBtn>
            {data.status !== '' ? (
              <Status type={data.status}>
                <StatusText type={data.status}>{data.status_str}</StatusText>
              </Status>
            ) : null}
            {data.status !== 'pass' ? (
              <Status
                type={data.status}
                style={{
                  borderBottom: '1px solid #000000',
                  color: '#000000',
                  borderRadius: '0',
                  background: 'transparent',
                }}
              >
                <StatusTitle
                  onClick={() => {
                    setIntroPopup(true);
                    setContentModal(data.remark);
                  }}
                  style={{ cursor: 'pointer', padding: '0px' }}
                >
                  {t('memberCenter.performance.intro')}
                </StatusTitle>
              </Status>
            ) : (
              <></>
            )}
          </ItemThreeBtn>
        </InfoItemThreeGroup>
        {data.files.length !== 0 ? (
          <ItemFourGroup>
            <ItemFourTitle>
              {t('memberCenter.performance.alreadyUpload')}
            </ItemFourTitle>
            <ItemFourContent>
              {data.files.map((file) => {
                if (data.file_required !== '0') {
                  if (file.can_del === true) {
                    return (
                      <UploadList key={file.id} data-id={id} data-key={file.id}>
                        {file.file_name}
                        <Action onClick={handleDelete}>
                          <FontAwesomeIcon
                            icon={['fas', 'trash']}
                            style={{ width: '0.75em' }}
                          />
                        </Action>
                      </UploadList>
                    );
                  } else {
                    return (
                      <UploadList key={file.id} data-id={id} data-key={file.id}>
                        {file.file_name}
                      </UploadList>
                    );
                  }
                } else if (data.file_required === '0') {
                  return (
                    <UploadList key={file.id} data-id={id} data-key={file.id}>
                      {file.file_name}
                    </UploadList>
                  );
                }
              })}
            </ItemFourContent>
          </ItemFourGroup>
        ) : (
          <></>
        )}
      </InfoContentGroup>,
    );
    mobileNumber.push(
      <MobileTable>
        <MobileItemOne>{data.period}</MobileItemOne>
        <MobileItemTwo>
          <MobileItemTwoTable>
            <tbody>
              <tr>
                <TableTitile>
                  {t('memberCenter.performance.deadline')}
                </TableTitile>
                <TableContent>{data.deadline}</TableContent>
              </tr>
              <tr>
                <TableTitile>
                  {t('memberCenter.performance.description')}
                </TableTitile>
                <TableContent>{data.des}</TableContent>
              </tr>
              <tr>
                <TableTitile>
                  {t('memberCenter.performance.currency')}
                </TableTitile>
                <TableContent>{data.currency}</TableContent>
              </tr>
              <tr>
                <TableTitile>
                  {t('memberCenter.performance.payables')}
                </TableTitile>
                <TableContent>{data.payables}</TableContent>
              </tr>
              <tr>
                <TableTitile>
                  {t('memberCenter.performance.receivables')}
                </TableTitile>
                <TableContent>{data.receivables}</TableContent>
              </tr>
              <tr>
                <TableTitile>
                  {t('memberCenter.performance.invoiceReceived')}
                </TableTitile>
                {data.is_invoice === true ? (
                  <TableContent>
                    {t('memberCenter.performance.isInvoice')}
                  </TableContent>
                ) : (
                  <TableContent>
                    {t('memberCenter.performance.isNotInvoice')}
                  </TableContent>
                )}
              </tr>
            </tbody>
          </MobileItemTwoTable>
        </MobileItemTwo>
        <MobileItemThree>
          <div>
            <MoblieItemThreeTitle>
              {t('memberCenter.performance.document')}
            </MoblieItemThreeTitle>
            <MobileItemThreeContent>
              {data.file_required === '0' || data.status === 'accepting' ? (
                <>-</>
              ) : (
                <UploadBtn
                  onClick={handleClick}
                  data-id={id}
                  data-key={data.period}
                >
                  {t('memberCenter.performance.upload')}
                  <FontAwesomeIcon icon={['fas', 'upload']} />
                </UploadBtn>
              )}
            </MobileItemThreeContent>
          </div>
          <div>
            <MoblieItemThreeTitle>
              {t('memberCenter.performance.status')}
            </MoblieItemThreeTitle>
            <MobileThreeBtn>
              {data.status !== '' ? (
                <Status type={data.status}>
                  <StatusText type={data.status}>{plan}</StatusText>
                </Status>
              ) : null}
              {data.status !== 'pass' ? (
                <Status
                  type={data.status}
                  style={{
                    borderBottom: '1px solid #000000',
                    color: '#000000',
                    borderRadius: '0',
                    background: 'transparent',
                  }}
                >
                  <StatusTitle
                    onClick={() => {
                      setIntroPopup(true);
                      setContentModal(data.remark);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('memberCenter.performance.intro')}
                  </StatusTitle>
                </Status>
              ) : (
                <></>
              )}
            </MobileThreeBtn>
          </div>
        </MobileItemThree>
        {data.files.length !== 0 ? (
          <div>
            <MobileFourItemTitle>
              {t('memberCenter.performance.alreadyUpload')}
            </MobileFourItemTitle>
            <MobileList>
              {data.files.map((file) => {
                if (data.file_required !== '0' && file.can_del === true) {
                  return (
                    <UploadList key={file.id} data-id={id} data-key={file.id}>
                      {file.file_name}
                      <Action onClick={handleDelete}>
                        <FontAwesomeIcon
                          icon={['fas', 'trash']}
                          className="delete"
                        />
                      </Action>
                    </UploadList>
                  );
                } else if (data.file_required === '0') {
                  return (
                    <UploadList key={file.id} data-id={id} data-key={file.id}>
                      {file.file_name}
                    </UploadList>
                  );
                }
              })}
            </MobileList>
          </div>
        ) : null}
      </MobileTable>,
    );
  });

  return (
    <Wrapper type={type}>
      <StatusGroup>
        <Statuses>
          <StatusTitle type={type}>{plan}</StatusTitle>
          {/* <Status type={type}></Status> */}
        </Statuses>
        <Actions>
          <Action type={type}>
            <ActionText type={type}>{plan_option}</ActionText>
          </Action>
        </Actions>
      </StatusGroup>
      <SchemeGroup>
        <Title>{title}</Title>
        <Time>{time}</Time>
      </SchemeGroup>
      {is_notify === true ? (
        <Accepting onClick={handleChange}>
          {t('memberCenter.performance.accepting')}
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={notify}
          >
            <circle cx="4" cy="4" r="4" fill="#FE3434" />
          </svg>
          {showMini === true ? (
            <FontAwesomeIcon icon={['fas', 'sort-up']} style={svgIconUp} />
          ) : (
            <FontAwesomeIcon icon={['fas', 'sort-down']} style={svgIconDown} />
          )}
        </Accepting>
      ) : (
        <Accepting onClick={handleChange}>
          {t('memberCenter.performance.accepting')}
          {showMini === true ? (
            <FontAwesomeIcon icon={['fas', 'sort-up']} style={svgIconUp} />
          ) : (
            <FontAwesomeIcon icon={['fas', 'sort-down']} style={svgIconDown} />
          )}
        </Accepting>
      )}
      {period.length > 0 ? (
        <>
          {!isLaptopL && (
            <InfoGroup style={showStyle} ref={parentRef}>
              <InfoTable>
                <InfoTitleGroup>
                  <div>{t('memberCenter.performance.period')}</div>
                  <div>{t('memberCenter.performance.deadline')}</div>
                  <div style={{ textAlign: 'left' }}>
                    {t('memberCenter.performance.description')}
                  </div>
                  <div>{t('memberCenter.performance.currency')}</div>
                  <div>{t('memberCenter.performance.payables')}</div>
                  <div>{t('memberCenter.performance.receivables')}</div>
                  <div>{t('memberCenter.performance.invoiceReceived')}</div>
                </InfoTitleGroup>
                {number}
              </InfoTable>
            </InfoGroup>
          )}
        </>
      ) : null}
      {period.length > 0 ? (
        <>
          {isLaptopL && (
            <MobileInfoGroup style={showStyle} ref={parentRef}>
              <>{mobileNumber}</>
            </MobileInfoGroup>
          )}
        </>
      ) : null}
      <Popup trigger={btnPopup} setTrigger={setBtnPopup}>
        <PopupTitle>{t('memberCenter.performance.uploadFile')}</PopupTitle>
        <PopupContent>
          <Upload
            multiple
            accept=".pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          >
            <></>
          </Upload>
        </PopupContent>
      </Popup>
      <Popup trigger={introPopup} setTrigger={setIntroPopup}>
        <PopupTitle>{t('memberCenter.performance.intro')}</PopupTitle>
        <PopupContent
          dangerouslySetInnerHTML={{
            __html: contentModal.replace(/\n/g, '<br />'),
          }}
        ></PopupContent>
      </Popup>
    </Wrapper>
  );
};

ContractCard.defaultProps = {
  type: '',
  title: '',
  scheme: '',
  time: '',
};

export default ContractCard;
