import axios from 'axios';
import storageKey from 'constant/storageKey';
import { writeStorage, deleteFromStorage } from '@rehooks/local-storage';

const apiUrl = `${process.env.REACT_APP_BASE_URL}/api`;

const getUserToken = () => {
  const userInfo = localStorage.getItem(storageKey.USER);
  const user = !!userInfo ? JSON.parse(userInfo) : {};
  return user.token || '';
};
const getLocale = () => {
  const locale = localStorage.getItem(storageKey.LOCALE);
  return locale === 'en-US' ? 'en' : locale;
};

const getBeta = () => {
  if (window.location.origin === 'https://anbon.vip') {
    return 1;
  } else if (window.location.origin === 'https://register.taicca.tw') {
    return 0;
  }
};

const api = {
  getContractFile: async (formData) => {
    const data = await axios.post(`${apiUrl}/contract_list`, formData);
    return data;
  },
  deleteContractFile: async (formData) => {
    return axios.post(`${apiUrl}/contract_file_delete`, formData);
  },
  uploadContractFile: async (formData) => {
    return axios.post(`${apiUrl}/contract_file_upload`, formData);
  },
  uploadImage: async (formData) => {
    return axios.post(`${apiUrl}/img_upload`, formData);
  },
  uploadFile: async (formData) => {
    return axios.post(`${apiUrl}/file_upload`, formData);
  },
  getCityList: async () => {
    const { data } = await axios.get(`${apiUrl}/citydata`);
    return data.data;
  },
  register: async (form) => {
    const mutation = await axios.post(`${apiUrl}/register`, form);
    if (!mutation.data.status) throw mutation.data.msg;
    return mutation;
  },
  login: async (form) => {
    const res = await axios.post(`${apiUrl}/login`, form);
    if (!res.data.status) throw res.data.msg;
    return res;
  },
  forgotPassword: async (form) => {
    const mutation = await axios.post(`${apiUrl}/forgetPwd`, form);
    if (!mutation.data.status) throw mutation.data.msg;
    return mutation;
  },
  // 以下是登入後的API
  changePassword: async (form) => {
    const token = getUserToken();
    const mutation = await axios.post(`${apiUrl}/changePwd`, {
      ...form,
      token,
    });
    if (!mutation.data.status) throw mutation.data.msg;
    return mutation;
  },
  invitationCodeVerify: async (form) => {
    const token = getUserToken();
    const mutation = await axios.post(`${apiUrl}/invitationCodeVerify`, {
      ...form,
      token,
    });
    if (!mutation.data.status) throw mutation.data.msg;
    return mutation.data;
  },
  memberApply: async (form) => {
    const token = getUserToken();
    const mutation = await axios.post(`${apiUrl}/memberApply`, {
      ...form,
      token,
    });
    if (!mutation.data.status) throw mutation.data.msg;

    const userInfo = localStorage.getItem(storageKey.USER);
    const user = !!userInfo ? JSON.parse(userInfo) : {};

    writeStorage(storageKey.USER, {
      ...user,
      role: 'mou',
    });
    return mutation;
  },
  recommendMemberApply: async (form) => {
    const token = getUserToken();
    const mutation = await axios.post(`${apiUrl}/memberApply`, {
      ...form,
      token,
    });
    if (!mutation.data.status) throw mutation.data.msg;

    const userInfo = localStorage.getItem(storageKey.USER);
    const user = !!userInfo ? JSON.parse(userInfo) : {};

    writeStorage(storageKey.USER, {
      ...user,
      role: 'recommend',
    });
    return mutation;
  },
  userInfo: async () => {
    const token = getUserToken();
    const { data } = await axios.post(`${apiUrl}/userinfo`, { token });
    if (!data.status) {
      deleteFromStorage(storageKey.USER);
      window.location.reload();
      return;
    }

    writeStorage(storageKey.USER, { ...data.data, token });
    return data;
  },
  contractList: async () => {
    const token = getUserToken();
    const lang = getLocale();
    const { data } = await axios.post(`${apiUrl}/contract_list`, {
      token,
      lang,
    });
    return data;
  },
  projectList: async (query) => {
    const { queryKey } = query;
    const [, pageIndex] = queryKey;
    const token = getUserToken();
    const lang = getLocale();
    const { data } = await axios.post(`${apiUrl}/projectList`, {
      page: pageIndex,
      token,
      lang,
    });
    return data;
  },
  planParams: async (query) => {
    const { queryKey } = query;
    const [, planId, planOption] = queryKey;
    const token = getUserToken();
    const lang = getLocale();
    const { data: res } = await axios.post(`${apiUrl}/planParams`, {
      planId,
      planOption,
      token,
      lang,
    });
    const formattedData = Object.keys(res.data).reduce(
      (current, key) => {
        if (key.includes('_title'))
          return {
            ...current,
            title: { ...current.title, [key]: res.data[key] },
          };
        return {
          ...current,
          formShape: {
            ...current.formShape,
            [key]: res.data[key].reduce((accumulator, field) => {
              if (field.name === 'projectSpec') {
                return [
                  ...accumulator,
                  field,
                  { ...field, name: 'projectSpecType' },
                  { ...field, name: 'projectSpecTime' },
                  { ...field, name: 'projectSpecEpisode' },
                ];
              }
              return [...accumulator, field];
            }, []),
          },
        };
      },
      { title: {}, formShape: {} },
    );
    return formattedData;
  },
  planParamsKey: async (query) => {
    const { queryKey } = query;
    const [, data] = queryKey;
    const token = getUserToken();
    const lang = getLocale();
    const { data: res } = await axios.post(`${apiUrl}/planParams/keyIndex`, {
      ...data,
      token,
      lang,
    });
    return res.data;
  },
  projectSave: async (data) => {
    const token = getUserToken();
    return axios.post(`${apiUrl}/projectSave`, { ...data, token });
  },
  projectCommit: async (data) => {
    const token = getUserToken();
    const mutation = await axios.post(`${apiUrl}/projectCommit`, {
      ...data,
      token,
    });
    if (mutation.data.status) {
      localStorage.removeItem('refferalCode');
    }
    if (!mutation.data.status) throw mutation.data.msg;
    return mutation;
  },
  projectDetail: async (query) => {
    const token = getUserToken();
    const { queryKey } = query;
    const [, pid] = queryKey;
    const { data: res } = await axios.post(`${apiUrl}/projectDetail`, {
      projectId: pid,
      token,
    });
    if (!res.status) throw res.msg;

    // projectSpec 是比較特別的欄位，資料由前端的三個欄位組成成一個欄位再送給後端，所以取得的時候要再把一個拆成三個
    const { projectSpec } = res.data;
    const SplittedProjectSpec = (projectSpec || '').split(' ');
    const hasProjectSpec = SplittedProjectSpec.length > 1;
    const projectSpecType = hasProjectSpec ? SplittedProjectSpec[0] : '';
    const projectSpecTime = hasProjectSpec ? SplittedProjectSpec[2] : '';
    const projectSpecEpisode = hasProjectSpec ? SplittedProjectSpec[5] : '';
    const projectDetail = {
      ...res.data,
      projectSpecType: projectSpecType,
      projectSpecTime: projectSpecTime,
      projectSpecEpisode: projectSpecEpisode,
    };
    return projectDetail;
  },
  projectDelete: async (projectId) => {
    const token = getUserToken();
    const mutation = await axios.post(`${apiUrl}/projectDelete`, {
      projectId,
      token,
    });
    return mutation;
  },
  resendVerifyCode: async (email) => {
    const mutation = await axios.post(`${apiUrl}/resendVerifyCode`, email);
    return mutation;
  },
  planSetting: async () => {
    const token = getUserToken();
    const lang = getLocale();
    const beta = getBeta();
    const { data: res } = await axios.post(`${apiUrl}/planSetting`, {
      token,
      lang,
      beta,
    });
    return res.data;
  },
  checkRefferalCode: async (code) => {
    const token = getUserToken();
    return await axios.post(`${apiUrl}/check_referral_code`, { token, code });
  },
};

export default api;
