import { useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from './FormControl';
import Input from './Input/Input';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

const SubsidyFormWrapper = styled.div`
  padding: 16px 32px;
  border: 1px dashed #bbbbbf;
  background: ${(props) => props.theme.color.bluegray100};
`;
const SubsidyCard = styled.div`
  position: relative;
`;
const Content = styled.div`
  color: ${(props) => props.theme.color.bluegray900};
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`;
const Actions = styled.div`
  display: flex;
  gap: 34px;
  position: absolute;
  inset: 0 0 auto auto;
`;
const Action = styled.div`
  font-size: 16px;
  cursor: pointer;
`;

function SubsidyForm(props) {
  const { data, formData, onChange, number } = props;
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const handleInputChange = (x) => (e) => {
    if (!onChange) return;
    onChange(
      formData.values.subsidiesInThePastThreeYears.map((subsidy, i) => {
        if (i !== number) return subsidy;
        return {
          ...data,
          [x]: e.target.value,
        };
      }),
    );
  };

  return (
    <>
      <SubsidyFormWrapper>
        {isEditing ? (
          <>
            <FormControl
              label={t('memberApply.subsidy.year')}
              isRequired={true}
            >
              <Input
                value={data.year}
                onChange={handleInputChange('year')}
                placeholder={t('common.pleaseEnter')}
              />
            </FormControl>
            <FormControl
              label={t('memberApply.subsidy.subsidizedProjectName')}
              isRequired={true}
            >
              <Input
                value={data.projectName}
                onChange={handleInputChange('projectName')}
                placeholder={t('common.pleaseEnter')}
              ></Input>
            </FormControl>
            <FormControl
              label={t('memberApply.subsidy.subsidyAgency')}
              isRequired={true}
            >
              <Input
                value={data.subsidyAgency}
                onChange={handleInputChange('subsidyAgency')}
                placeholder={t('common.pleaseEnter')}
              ></Input>
            </FormControl>
            <FormControl
              label={t('memberApply.subsidy.subsidyAmount')}
              isRequired={true}
            >
              <Input
                value={data.subsidyAmount}
                onChange={handleInputChange('subsidyAmount')}
                placeholder={t('common.pleaseEnter')}
              ></Input>
            </FormControl>
            <br />
            <Button
              onClick={() => {
                setIsEditing(!isEditing);
              }}
            >
              {t('common.confirm')}
            </Button>
          </>
        ) : (
          <SubsidyCard>
            <Content>
              {t('memberApply.subsidy.year')}：{`${data.year}`}
            </Content>
            <Content>
              {t('memberApply.subsidy.subsidizedProjectName')}：
              {`${data.projectName}`}
            </Content>
            <Content>
              {t('memberApply.subsidy.subsidyAgency')}：
              {`${data.subsidyAgency}`}
            </Content>
            <Content>
              {t('memberApply.subsidy.subsidyAmount')}：
              {`${data.subsidyAmount}`}
            </Content>
            <Actions>
              <Action
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              >
                <FontAwesomeIcon icon={['fas', 'pen']} />
              </Action>
              <Action
                onClick={() => {
                  const a = formData.values.subsidiesInThePastThreeYears;
                  const b = a.filter((data, i) => i !== number);
                  onChange(b);
                }}
              >
                <FontAwesomeIcon icon={['fas', 'trash']} />
              </Action>
            </Actions>
          </SubsidyCard>
        )}
      </SubsidyFormWrapper>
    </>
  );
}

export default SubsidyForm;
