import { FormHeading, Description } from 'component/Typography';
import { Button } from 'component/Button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Desc = styled(Description)`
  margin-bottom: 32px;
  white-space: pre-line;
`;

function Flow2(props) {
  const { formData } = props;
  const { email } = formData.values;
  const { t } = useTranslation();
  return (
    <>
      <FormHeading>{t('common.flow.yourApplicationHasSent')}</FormHeading>
      <Desc>
        {t('forgot.pleaseNoteYourEmail', {
          userEmail: email,
        })}
      </Desc>
      <Button as={Link} to="/" color="primary">
        {t('common.goHome')}
      </Button>
    </>
  );
}

export { Flow2 };
