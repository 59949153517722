import { FormHeading, Description } from 'component/Typography';
import { Button } from 'component/Button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

const Desc = styled(Description)`
  margin-bottom: 32px;
  white-space: pre-line;
`;

function Flow4() {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const history = useHistory();
  const { values } = formik;
  const { email } = values;

  return (
    <>
      <FormHeading>{t('common.flow.yourApplicationHasSent')}</FormHeading>
      <Desc>
        {t('register.flow4.pleaseNoteYourEmail', {
          userEmail: email,
        })}
      </Desc>
      <Button
        color="primary"
        onClick={() => {
          history.push('/');
        }}
      >
        {t('common.goHome')}
      </Button>
    </>
  );
}

export { Flow4 };
