import { useState, useEffect, useContext, useMemo } from 'react';
import { TaiccaContext } from 'App';
import {
  Tabs as TabsBase,
  TabPanels as TabPanelsBase,
  VisibleTabPanels,
} from 'component/Tab';

function useTabs({
  options,
  hasNumber,
  align,
  lock,
  onChange,
  isDisplayShowHide = false,
}) {
  const [selectedValue, setSelectedValue] = useState(options[0]?.value);
  const { tabIndex, setTabIndex } = useContext(TaiccaContext);
  const tabs = useMemo(() => {
    return {
      Tabs: (
        <TabsBase
          value={selectedValue}
          hasNumber={hasNumber}
          align={align}
          lock={lock}
          options={options}
          onChange={(option) => {
            setSelectedValue(option.value);
            if (onChange) {
              onChange(option);
            }
          }}
        />
      ),
      TabPanels: isDisplayShowHide ? (
        <VisibleTabPanels value={selectedValue} options={options} />
      ) : (
        <TabPanelsBase value={selectedValue} options={options} />
      ),
      selectedTab: selectedValue,
      setSelectedTab: setSelectedValue,
      isFirstTab: tabIndex === 0,
      isLastTab: tabIndex === options.length - 1,
      next: () => {
        if (tabIndex === options.length - 1) return;
        setSelectedValue(options[tabIndex + 1].value);
      },
      prev: () => {
        if (tabIndex === 0) return;
        setSelectedValue(options[tabIndex - 1].value);
      },
      tabOptions: options,
    };
  }, [
    selectedValue,
    setSelectedValue,
    tabIndex,
    options,
    hasNumber,
    align,
    lock,
    isDisplayShowHide,
    onChange,
  ]);
  const { setNowTabs } = useContext(TaiccaContext);
  useEffect(() => {
    // options
    setNowTabs(tabs);
    return () => {
      setNowTabs(null);
    };
  }, [setNowTabs, tabs]);

  useEffect(() => {
    const index = options.findIndex((option) => option.value === selectedValue);
    setTabIndex(index);
  }, [selectedValue, setTabIndex, options]);

  return tabs;
}

export default useTabs;
