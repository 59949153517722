import React, { useState } from 'react';
import { FormHeading, Description } from 'component/Typography';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import api from 'api';
import { useMedia } from 'react-use';
import useUser from 'hook/useUser';
import polygon from 'asset/Polygon.png';
import ContractCard from 'component/ContractCard';
import { Button } from 'component/Button';
import MemberApplicationDialog from 'app/MemberApplicationDialog/MemberApplicationDialog';

const MobileFormHeading = styled.h3`
  font-weight: 600;
  line-height: 34px;
  position: absolute;
  background: ${(props) => props.theme.color.bluegray900};
  color: #ffffff;
  top: -57px;
  height: 39px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
`;

const Desc = styled(Description)`
  margin: 0 auto 32px;
  font-size: 16px;
  white-space: pre-line;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_L} {
    margin-bottom: 15px;
  }
  ${(props) => props.theme.media.mobile_S} {
    margin-bottom: 55px !important;
  }
`;
const Action = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  position: absolute;
  inset: 60px 17px auto auto;
  ${(props) => props.theme.media.laptop_M} {
    position: initial;
    justify-content: flex-end;
    margin-bottom: 15px;
    flex-direction: row;
    gap: 10px;
  }
  ${(props) => props.theme.media.tablet_L} {
    margin-bottom: 15px;
  }
`;
const leftAlignStyle = css`
  text-align: left;
`;
const centerAlignStyle = css`
  text-align: center;
`;
const rightAlignStyle = css`
  text-align: right;
`;
const alignStyle = {
  left: leftAlignStyle,
  center: centerAlignStyle,
  right: rightAlignStyle,
};
const Select = styled.select`
  padding: 10px;
  width: 160px;
  border: solid 1px ${(props) => props.theme.color.bluegray300};
  appearance: none;
  background-position: calc(100% - 20px) calc(1em + -1px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-image: url(${polygon});
  background-repeat: no-repeat;
  outline: none;
  ${(props) => props.theme.media.laptop_M} {
    width: 150px;
  }
`;
const Option = styled.option`
  color: ${(props) => props.theme.color.bluegray900};
`;
const DescWrapper = styled.div`
  position: absolute;
  inset: 50% 30px auto 30px;
  transform: translate(0, -50%);
  ${(props) => alignStyle[props.align] || alignStyle.center}
`;
const PerforList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

function Performance() {
  const { t } = useTranslation();
  const user = useUser();
  const { verified, role } = user;
  const contractListQuery = useQuery('contractList', api.contractList);
  const [isOpen, setIsOpen] = useState(false);
  const isTabletS = useMedia('(max-width: 551px)');

  return (
    <>
      {!isTabletS && (
        <FormHeading>{t('memberCenter.performance.heading')}</FormHeading>
      )}
      {isTabletS && (
        <MobileFormHeading>
          {t('memberCenter.performance.heading')}
        </MobileFormHeading>
      )}
      <Desc>{t('memberCenter.performance.information')}</Desc>
      <Action>
        {t('memberCenter.performance.sort')}
        <Select>
          <Option>{t('memberCenter.performance.cases.new')}</Option>
        </Select>
      </Action>
      {contractListQuery.isLoading ? (
        <div>Loading...</div>
      ) : contractListQuery.data.data !== [] ? (
        <>
          <PerforList>
            {contractListQuery.data.data.map((item) => {
              // const path = (() => {
              //   if (item.status.code === 'success') {
              //     return item.action.link;
              //   }
              //   if (item.planId === planIdMap.MARKETING)
              //     return `/scheme/${planIdMap[item.planId]}/${item.id}`;
              //   return `/scheme/${planIdMap[item.planId]}/${
              //     item.planOption
              //   }/${item.id}`;
              // })();
              return (
                <ContractCard
                  type={item.status}
                  id={item.id}
                  title={item.title || t('common.none')}
                  time={
                    t('memberCenter.performance.createDate') +
                    ` ${item.create_date}`
                  }
                  plan={item.plan}
                  plan_option={item.plan_option}
                  is_notify={item.is_notify}
                  period={item.period}
                />
              );
            })}
          </PerforList>
        </>
      ) : (
        <DescWrapper>
          <Desc>{t('memberCenter.myRecord.noPerformance')}</Desc>
        </DescWrapper>
      )}
    </>
  );
}

export { Performance };
