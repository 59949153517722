import { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import arrowUrl from 'asset/arrow_right.png';
import diamondUrl from 'asset/diamond.png';
import Input from 'component/Input/Input';
import FormControl from 'component/FormControl';
import { useMutation } from 'react-query';
import api from 'api';
import { useTranslation } from 'react-i18next';
import { TaiccaContext } from 'App';

const Arrow = styled.div`
  img {
    position: absolute;
    inset: auto 40px 40px auto;
    width: 90px;
    height: 10px;
    -webkit-filter: grayscale(1);
    opacity: 0.6;
    transition: 0.3s;
    ${(props) => props.theme.media.tablet_L} {
      inset: auto 30px 30px auto;
    }
  }
`;
const Diamond = styled.div`
  position: absolute;
  inset: 25px auto auto 50%;
  transform: translate(-50%, 0);
  img {
    width: 44px;
    height: 44px;
    opacity: 0;
    transition: 0.3s;
  }
`;
const Heading = styled.p`
  color: ${(props) => props.theme.color.bluegray600};
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  transition: 0.3s;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 28px;
  }
  ${(props) => props.theme.media.laptop_S} {
    font-size: 26px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 24px;
  }
  ${(props) => props.theme.media.mobile_L} {
    font-size: 22px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 20px;
  }
`;

const Desc = styled.p`
  margin-top: 32px;
  color: ${(props) => props.theme.color.bluegray600};
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  transition: 0.3s;
  ${(props) => props.theme.media.laptop_M} {
    margin-top: 24px;
    font-size: 14px;
  }
  ${(props) => props.theme.media.laptop_S} {
    font-size: 13px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 12px;
  }
  ${(props) => props.theme.media.mobile_L} {
    margin-top: 12px;
  }
`;
const DisableDesc = styled.div`
  color: ${(props) => props.theme.color.bluegray900};
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  transition: 0.3s;
  ${(props) => props.theme.media.laptop_M} {
    margin-top: 24px;
    font-size: 14px;
  }
  ${(props) => props.theme.media.laptop_S} {
    font-size: 13px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 12px;
  }
  ${(props) => props.theme.media.mobile_L} {
    margin-top: 12px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 100%;
  width: 400px;
  padding: 91px 50px 90px;
  border: 1px solid ${(props) => props.theme.color.bluegray400};
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};
  transition: 0.3s;
  ${(props) => props.theme.media.laptop_M} {
    padding: 91px 40px 90px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 65px 30px 70px;
  }
  ${(props) => props.theme.media.tablet_M} {
    width: 450px;
    padding: 45px 30px 60px;
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.color.primary500};
    ${Arrow} {
      img {
        -webkit-filter: grayscale(0);
        opacity: 1;
      }
    }
    ${Diamond} {
      img {
        opacity: 1;
      }
    }
    ${Heading} {
      color: ${(props) => props.theme.color.bluegray900};
    }
    ${Desc} {
      color: ${(props) => props.theme.color.bluegray900};
    }
    ${DisableDesc} {
      color: ${(props) => props.theme.color.bluegray900};
    }
  }
  &:focus {
    ${Heading} {
      color: ${(props) => props.theme.color.bluegray900};
    }
    ${Desc} {
      color: ${(props) => props.theme.color.bluegray900};
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 82px;
  ${(props) => props.theme.media.tablet_L} {
    margin-top: 32px;
  }
`;

const Card = (props) => {
  const { disable, title, children, hasInput } = props;
  const { setHasThroughRecommendApply } = useContext(TaiccaContext);
  const { t } = useTranslation();
  const memberApplyPath = () => {
    history.push('member-application/');
    setHasThroughRecommendApply(false);
  };
  const applicationMutation = useMutation(api.invitationCodeVerify, {
    onSuccess: (value) => {
      if (value.status) {
        setHasThroughRecommendApply(true);
        history.push('recommend-member-application/');
      }
    },
  });
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      applicationMutation.mutate({ invitationCode: codeValue });
    }
  };
  const [codeValue, setCodeValue] = useState('');
  const history = useHistory();
  const inputFocus = useRef();

  return (
    <>
      <CardWrapper
        disable={disable}
        onClick={
          disable
            ? () => {}
            : hasInput
            ? () => {
                if (codeValue === '') {
                  inputFocus.current.focus();
                  return;
                }
                applicationMutation.mutate({ invitationCode: codeValue });
                // if (!inputFocus.current) return;
                // inputFocus.current.focus();
              }
            : memberApplyPath
        }
      >
        <div>
          <Heading>{title}</Heading>
          <Desc>{children}</Desc>
        </div>
        {hasInput && (
          <>
            <Diamond>
              <img src={diamondUrl} alt="diamond" />
            </Diamond>
            <InputWrapper>
              <FormControl
                label={t('memberApply.dialog.card.enterCode')}
                isRequired={true}
                isError={applicationMutation.isError}
                errorMessage={applicationMutation.error}
              >
                <Input
                  value={codeValue}
                  ref={inputFocus}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => {
                    setCodeValue(e.target.value);
                  }}
                />
              </FormControl>
            </InputWrapper>
          </>
        )}
        {disable && (
          <DisableDesc>{t('memberApply.dialog.card.disableApply')}</DisableDesc>
        )}
        <Arrow>
          <img src={arrowUrl} alt="arrow_right" />
        </Arrow>
      </CardWrapper>
    </>
  );
};

export default Card;
