import { useState } from 'react';
import CameraLayout from 'layout/CameraLayout';
import { Sidebar, FormContentWrapper, FormFooter } from 'layout/shared';
import * as ForgotForm from 'app/Form/Forgot';
import { PageHeading } from 'component/Typography';
import { Button } from 'component/Button';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useMutation } from 'react-query';
import { Error } from 'component/Typography';
import styled from 'styled-components';

const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
function ForgotPage() {
  const { t } = useTranslation();
  const forgotMutation = useMutation(api.forgotPassword);
  const [formData, setFormData] = useState({
    values: {
      applicant: '',
      email: '',
    },
  });

  return (
    <CameraLayout
      progressBlock={
        <Sidebar>
          <PageHeading>{t('header.forgotPage')}</PageHeading>
        </Sidebar>
      }
    >
      <FormContentWrapper>
        {forgotMutation.isSuccess ? (
          <ForgotForm.Flow2 formData={formData} />
        ) : (
          <ForgotForm.Flow1 formData={formData} onChange={setFormData} />
        )}
      </FormContentWrapper>
      {!forgotMutation.isSuccess && (
        <FormFooter>
          <div />
          <MessageBlock>
            {forgotMutation.isError && <Error>{forgotMutation.error}</Error>}
            <Button
              onClick={() => {
                forgotMutation.mutate(formData.values);
              }}
              color="primary"
            >
              {t('common.sendForm')}
            </Button>
          </MessageBlock>
        </FormFooter>
      )}
    </CameraLayout>
  );
}

export default ForgotPage;
