const colorChart = {
  primary050: '#fef6f4',
  primary100: '#fde8e5',
  primary200: '#fabeb5',
  primary300: '#f69486',
  primary400: '#f36a56',
  primary500: '#ef4027',
  primary600: '#d32810',
  primary700: '#a41f0c',
  primary800: '#751609',
  primary900: '#450d05',
  bluegray000: '#ffffff',
  bluegray050: '#f8f8f9',
  bluegray100: '#efeff0',
  bluegray200: '#d5d5d8',
  bluegray300: '#bbbbbf',
  bluegray400: '#a0a1a7',
  bluegray500: '#86878e',
  bluegray600: '#6d6e74',
  bluegray700: '#54555a',
  bluegray800: '#333437',
  bluegray900: '#121314',
  success: '#41D4B3',
  warning: '#FFA336',
};

export default colorChart;
