import styled from 'styled-components/macro';
import { useEffect, useMemo, useState } from 'react';
import FormControl from 'component/FormControl';
import Input, { TextField } from 'component/Input/Input';
import { FormRow } from 'layout/shared';
import { FormHeading } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Upload from 'app/Form/component/Upload';
import Textarea from 'component/Input/Textarea';
import RadioGroup from 'component/Input/Radio/RadioGroup';
import Radio from 'component/Input/Radio/Radio';
import Percentage from 'utils/Percentage';

const TelFormRow = styled(FormRow)`
  ${TextField} {
    &:nth-child(1) {
      width: 52%;
    }
    &:nth-child(2) {
      width: 24%;
    }
    &:nth-child(3) {
      width: 24%;
    }
  }
`;

// projectSpec 是比較特別的欄位，資料由前端的三個欄位組成成一個欄位再送給後端，所以取得的時候要再把一個拆成三個
export const firstStepExtraNameList = [
  'projectSpecType',
  'projectSpecTime',
  'projectSpecEpisode',
];

function Builder(props) {
  const { t } = useTranslation();
  const { formData, onChange, form, id, title, isEdit } = props;
  const initialValues = useMemo(
    () => {
      return {
        ...formData.values,
        projectCurrency: formData.values.projectCurrency || undefined,
      };
    },
    // eslint-disable-next-line
    [],
  );
  const isFirstStep = id === 0;
  const firstStepExtraValidation = {
    projectSpecType: Yup.string().required(t('common.validation.required')),
    projectSpecTime: Yup.string()
      .required(t('common.validation.required'))
      .test(
        'number',
        `${t('common.mostBeNumber')}`,
        (val) => !isNaN(Number(val)),
      ),
    projectSpecEpisode: Yup.string().when(
      'projectSpecTime',
      (projectSpecTime, schema) => {
        return schema
          .required(t('common.validation.required'))
          .test(
            'number',
            `${t('common.mostBeNumber')}`,
            (val) => !isNaN(Number(val)),
          )
          .test({
            test: (projectSpecEpisode) => {
              return (
                projectSpecEpisode * projectSpecTime > 60 &&
                projectSpecEpisode * projectSpecTime < 781
              );
            },
            message: t('scheme.projectSpec.message'),
          });
      },
    ),
  };
  const validationSchema = useMemo(() => {
    return {
      ...form
        .filter((field) => field.required)
        .reduce((accumulator, field) => {
          if (field.name === 'projectSpec') return accumulator;
          const validation = (() => {
            let val = undefined;
            if (field.required) {
              switch (field.type) {
                case 'image':
                case 'file':
                  val = Yup.array().length(1, t('common.validation.required'));
                  break;
                default:
                  val = Yup.string().required(t('common.validation.required'));
              }
            }
            if (field.type === 'number') {
              return val.test(
                'number',
                t('common.validation.numberRequired'),
                (val) => {
                  if (!val) return true;
                  return !isNaN(Number(val));
                },
              );
            }
            return val;
          })();
          return {
            ...accumulator,
            [field.name]: validation,
          };
        }, {}),
      ...(isFirstStep ? firstStepExtraValidation : {}),
    };
    // eslint-disable-next-line
  }, [form, t]);
  const formik = useFormik({
    initialValues: initialValues,
    initialTouched: formData[id] ? formData[id].touched : {},
    validateOnMount: true,
    validationSchema: Yup.object(validationSchema),
  });
  const { values, touched, errors } = formik;
  useEffect(() => {
    if (onChange) {
      // 去除空值
      const isFirstStep = id === 0;
      const theStepFieldNameList = [
        ...form.map((f) => f.name),
        ...(isFirstStep ? firstStepExtraNameList : []),
      ];
      const newValues = Object.keys(values)
        .filter((fieldName) => theStepFieldNameList.includes(fieldName))
        .reduce((prev, current) => {
          return {
            ...prev,
            [current]: values[current],
          };
        }, {});
      onChange({
        ...formData,
        values: { ...formData.values, ...newValues },
        [id]: { touched, errors },
      });
    }
    // eslint-disable-next-line
  }, [onChange, values, touched, errors]);
  const [otherValue, setOtherValue] = useState('');
  useEffect(() => {
    if (!formData.values.projectCurrency) return;
    if (formData.values.projectCurrency === 'usd') return;
    if (formData.values.projectCurrency === 'ntd') return;
    setOtherValue(formData.values.projectCurrency);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormHeading>{title}</FormHeading>
      {form.map((field) => {
        if (firstStepExtraNameList.includes(field.name)) return null;
        const isTouched = !!touched[field.name] || isEdit;
        const tip =
          field.name === 'projectFunding'
            ? t('common.flow.proportion', {
                proportion: Percentage(
                  formData.values.projectFunding,
                  formData.values.projectBudget,
                ),
              })
            : '';
        const isError =
          field.name === 'projectSpec'
            ? !!(touched?.projectSpecType && errors?.projectSpecType) ||
              !!(touched?.projectSpecTime && errors?.projectSpecTime) ||
              !!(touched?.projectSpecEpisode && errors?.projectSpecEpisode)
            : isTouched && errors[field.name];
        const errorMessage =
          field.name === 'projectSpec'
            ? (touched?.projectSpecType && errors?.projectSpecType) ||
              (touched?.projectSpecTime && errors?.projectSpecTime) ||
              (touched?.projectSpecEpisode && errors?.projectSpecEpisode)
            : errors[field.name];
        return (
          <FormControl
            isRequired={field.required}
            label={field.title}
            desc={field.sub_title}
            tip={tip}
            placement="top-left"
            key={field.name}
            isError={isError}
            errorMessage={errorMessage}
          >
            {((type) => {
              if (field.name === 'projectSpec') {
                return (
                  <TelFormRow>
                    <Input
                      placeholder={t('scheme.projectSpec.type')}
                      id="projectSpecType"
                      isError={
                        !!(touched?.projectSpecType && errors?.projectSpecType)
                      }
                      {...formik.getFieldProps('projectSpecType')}
                    />
                    <Input
                      placeholder={t('scheme.projectSpec.time')}
                      id="projectSpecTime"
                      isError={
                        !!(touched?.projectSpecTime && errors?.projectSpecTime)
                      }
                      {...formik.getFieldProps('projectSpecTime')}
                    />
                    <Input
                      placeholder={t('scheme.projectSpec.episode')}
                      id="projectSpecEpisode"
                      isError={
                        !!(
                          touched?.projectSpecEpisode &&
                          errors?.projectSpecEpisode
                        )
                      }
                      {...formik.getFieldProps('projectSpecEpisode')}
                    />
                  </TelFormRow>
                );
              }
              switch (type) {
                case 'text':
                case 'number':
                  return (
                    <Input
                      placeholder={field.placeholder}
                      id={field.name}
                      {...formik.getFieldProps(field.name)}
                    />
                  );

                // case 'number':
                //   return (
                //     <Input
                //       type="number"
                //       placeholder={field.placeholder}
                //       id={field.name}
                //       {...formik.getFieldProps(field.name)}
                //     />
                //   );
                case 'textarea':
                  return (
                    <Textarea
                      placeholder={field.placeholder}
                      id={field.name}
                      {...formik.getFieldProps(field.name)}
                    />
                  );
                case 'radio':
                  return (
                    <RadioGroup
                      id={field.name}
                      {...formik.getFieldProps(field.name)}
                      onChange={(x) => {
                        formik.setFieldValue(field.name, x);
                      }}
                    >
                      {field.options &&
                        field.options.map((opt) => {
                          if (opt.value === 'other') {
                            return (
                              <Radio
                                hasInput
                                setOtherValue={(value) => {
                                  setOtherValue(value);
                                  formik.setFieldValue(
                                    'projectCurrency',
                                    value || '',
                                  );
                                }}
                                key={opt.value}
                                value={otherValue}
                              >
                                {opt.text}
                              </Radio>
                            );
                          }
                          return (
                            <Radio key={opt.value} value={opt.value}>
                              {opt.text}
                            </Radio>
                          );
                        })}
                    </RadioGroup>
                  );
                case 'file':
                case 'image':
                  return (
                    <Upload
                      id={field.name}
                      type={type}
                      {...formik.getFieldProps(field.name)}
                      value={
                        formik.values[field.name] &&
                        formik.values[field.name].map((x, i) => {
                          const theField = formik.values[field.name][i];
                          return {
                            name: theField.fileName,
                            preview: theField.fileLink || theField.fullPath,
                          };
                        })
                      }
                      onChange={(x) => {
                        formik.setFieldTouched(field.name, true);
                        formik.setFieldValue(field.name, x);
                      }}
                    />
                  );
                default:
                  return null;
              }
            })(field.type)}
          </FormControl>
        );
      })}
    </>
  );
}

export default Builder;
