import React from 'react';
import styled, { css } from 'styled-components/macro';
import { memo } from 'react';

const errorStyle = css`
  border: 1px solid ${(props) => props.theme.color.primary500};
  &:hover {
    border: 1px solid ${(props) => props.theme.color.primary500};
  }
`;

const TextField = styled.label`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.color.bluegray300};
  background-color: ${(props) => props.theme.color.bluegray000};
  color: ${(props) => props.theme.color.bluegray300};
  cursor: text;
  height: 46px;
  width: 100%;
  padding: 12px 13px;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${(props) => props.theme.color.bluegray500};
  }
  ${(props) => props.theme.media.laptop_M} {
    height: 42px;
  }
  ${(props) => props.theme.media.mobile_M} {
    height: 38px;
  }

  ${(props) => (props.isError ? errorStyle : null)}
`;

const TextInput = styled.input`
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.color.bluegray900};
  width: 100%;
  margin: 0;
  padding: 0;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 12px;
  }

  ${(props) =>
    props.hasPrefix &&
    css`
      margin-left: 4px;
    `}
  ${(props) =>
    props.hasSuffix &&
    css`
      margin-right: 4px;
    `}

  // 防止 autoComplete 的預設顏色
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Input = React.forwardRef(({ prefix, suffix, isError, ...props }, ref) => (
  <TextField isError={isError}>
    {prefix}
    <TextInput
      type="text"
      hasPrefix={!!prefix}
      hasSuffix={!!suffix}
      {...props}
      ref={ref}
    />
    {suffix}
  </TextField>
));

Input.defaultProps = {
  prefix: null,
  suffix: null,
};

export default memo(Input);
export { TextField, TextInput };
