import React from 'react';
import styled from 'styled-components/macro';
import { filesUpload } from 'component/Input/ContractUpload';

const PopupGroup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  padding: 0 15px;
`;

const PopupInner = styled.div`
  position: relative;
  width: 100%;
  max-width: 689px;
  background: #ffffff;
  border: 1px solid #eeeeee;
`;

const PopupClose = styled.button`
  cursor: pointer;
  position: absolute;
  background: transparent;
  border: none;
  top: -45px;
  right: -55px;
  ${(props) => props.theme.media.tablet_L} {
    top: -55px;
    right: -20px;
  }
`;

const Popup = (props) => {
  const handleClose = () => {
    props.setTrigger(false);
    filesUpload.length = 0;
  };
  return props.trigger ? (
    <PopupGroup>
      <PopupInner>
        <PopupClose onClick={handleClose}>
          <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_2617_6707)">
              <path d="M11 7L42.5 38.5" stroke="white" stroke-width="4" />
              <path d="M42.5 7L11 38.5" stroke="white" stroke-width="4" />
            </g>
            <defs>
              <filter
                id="filter0_d_2617_6707"
                x="0.585938"
                y="0.585938"
                width="52.3281"
                height="52.3281"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.34 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2617_6707"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2617_6707"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </PopupClose>
        {props.children}
        {/* <PopupTitle>上傳檔案</PopupTitle>
        <PopupContent>
          <Upload multiple accept=".pdf">
            <></>
          </Upload>
        </PopupContent> */}
      </PopupInner>
    </PopupGroup>
  ) : (
    ''
  );
};

export default Popup;
