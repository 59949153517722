import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button } from 'component/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import color from 'constant/color';
import { useMutation } from 'react-query';
import api from 'api';
import storageKey from 'constant/storageKey';

const uploadDragoverStyle = css`
  border: 1px solid ${(props) => props.theme.color.primary600};
`;

const StyledUpload = styled.div`
  box-sizing: border-box;
  background: ${(props) => props.theme.color.bluegray050};
  border: 1px dashed ${(props) => props.theme.color.bluegray300};
  border-radius: 8px;
  width: 100%;
  min-height: 155px;
  padding: 20px 24px 15px 24px;
  color: ${(props) => props.theme.color.bluegray300};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  color: #6a6868;
  ${(props) => props.theme.media.mobile_M} {
    padding: 10px;
  }

  ${(props) => props.isDragActive && uploadDragoverStyle}

  ${Button} {
    border: 1px solid #6a6868;
    border-radius: 6px;
    color: #6a6868;
    background: transparent;
    padding: 6px 20px;
    margin: 15px 0 6px 0;
    font-size: 12px;
`;

const AlreadyUploadContent = styled.div`
  margin: 35px 0 0 0;
`;

const AlreadyUploadTitle = styled.div`
  background: #e0e0e0;
  text-align: center;
  font-size: 13px;
  color: #4f4f4f;
  padding: 10px 0;
`;

const UploadListContent = styled.div`
  max-height: 100px;
  overflow: auto;
`;

const AlreadyUploadList = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr auto;
  border: 1px solid #e2e2e2;
`;

const ListItemOne = styled.div`
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  font-size: 10px;
  color: #7b7b7b;
`;

const ListItemTwo = styled.div`
  font-size: 12px;
  color: #7b7b7b;
  padding: 9px 0 9px 10px;
`;

const ListItemThree = styled.div`
  font-size: 12px;
  color: #7b7b7b;
  padding: 9px 20px 9px 0;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 45px;
`;

const CancelBtn = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  border-bottom: 1px solid #d9d7d7;
  font-size: 12px;
  color: #7b7b7b;
`;

const cloud = {
  width: '40px',
  height: '40px',
  marginBottom: '4px',
};

const getUserToken = () => {
  const userInfo = localStorage.getItem(storageKey.USER);
  const user = !!userInfo ? JSON.parse(userInfo) : {};
  return user.token || '';
};

export const filesUpload = [];

/**
 * `Upload` 是一個上傳元件。幫助我們能夠發佈文字、圖片、影片、檔案到後端伺服器上。
 */
const Upload = ({ resetKey, children, onChange, accept, value, ...props }) => {
  const token = getUserToken();
  const contractId = localStorage.getItem('ContractId');
  const periodId = localStorage.getItem('Period');
  const fileMutation = useMutation(api.uploadFile);
  const fileContractMutation = useMutation(api.uploadContractFile);
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const usedFiles = value || files;

  const resetInput = () => {
    setFiles([]);
    filesUpload.length = 0;
    if (onChange) onChange([]);
  };

  const handleOnFileDrop = (files) => {
    files.map((file) => {
      if (file.size / 1024 / 1024 <= 30) {
        const fd = new FormData();
        fd.append('file', file);
        if (fd['file'] !== file) {
          fd['file'] = file;
          fileMutation
            .mutateAsync(fd)
            .then((res) => {
              setFiles((prev) => [
                ...prev,
                {
                  name: file.name,
                  size: file.size,
                },
              ]);
              filesUpload.push(res.data.fileId);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        alert(t('memberCenter.performance.fileTooLarge'));
      }
    });

    if (onChange) onChange(files);
    return filesUpload;
  };

  const handleUpload = (e) => {
    const data = {
      token: token,
      contract_id: contractId,
      period: periodId,
      file_id: filesUpload,
    };
    fileContractMutation
      .mutateAsync(data)
      .then((res) => {
        if (res.data.status === true) {
          // alert(res.data.msg);
          e.target.innerHTML = res.data.msg;
          filesUpload.length = 0;
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
          window.location.reload();
        } else {
          alert(res.data.msg);
          resetInput();
        }
      })
      .catch((error) => {
        alert(t('memberCenter.performance.uploadFail'));
        resetInput();
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnFileDrop,
    accept,
  });

  const { onClick: clickUploadZone, ...rootProps } = getRootProps();
  const { ...inputProps } = getInputProps();

  return (
    <>
      <StyledUpload isDragActive={isDragActive} {...rootProps}>
        <input
          type="file"
          isDragActive={isDragActive}
          {...inputProps}
          {...props}
        />
        {isDragActive ? (
          <FontAwesomeIcon color={color.primary600} icon={['fal', 'plus']} />
        ) : (
          <>
            <FontAwesomeIcon icon={['fas', 'cloud-upload']} style={cloud} />
            {t('memberCenter.performance.dropFile')}
            <Button onClick={clickUploadZone}>
              {t('memberCenter.performance.selectFile')}
              <FontAwesomeIcon icon={['fas', 'upload']} />
            </Button>
            {/* {t('memberCenter.performance.acceptFile')} .pdf */}
          </>
        )}
      </StyledUpload>
      {usedFiles.length > 0 ? (
        <AlreadyUploadContent>
          <AlreadyUploadTitle>
            {t('memberCenter.performance.alreadyUploadList')}
          </AlreadyUploadTitle>
          <UploadListContent>
            {usedFiles.map((item, idx) => (
              <AlreadyUploadList>
                <ListItemOne>{idx + 1}.</ListItemOne>
                <ListItemTwo>{item.name}</ListItemTwo>
                <ListItemThree>
                  {(item.size / 1024 / 1024).toFixed(1)} MB
                </ListItemThree>
              </AlreadyUploadList>
            ))}
          </UploadListContent>
        </AlreadyUploadContent>
      ) : null}
      <BtnGroup>
        <CancelBtn onClick={resetInput}>
          {t('memberCenter.performance.uploadCancel')}
        </CancelBtn>
        <Button onClick={handleUpload}>
          {t('memberCenter.performance.uploadNext')}
        </Button>
      </BtnGroup>
    </>
  );
};

Upload.propTypes = {
  /**
   * 重設鍵值，鍵值被改變時 input value 會被重設
   */
  resetKey: PropTypes.number,
  /**
   * 限制檔案類型
   */
  accept: PropTypes.string,
  /**
   * 是否選取多個檔案
   */
  multiple: PropTypes.bool,
  /**
   * 選取上傳檔案時的 callback
   */
  onChange: PropTypes.func,
  /**
   * 內容，這邊指的是上傳按鈕外觀
   */
  children: PropTypes.element.isRequired,
};

Upload.defaultProps = {
  resetKey: 0,
  accept: undefined,
  multiple: true,
  onChange: () => {},
};

export default Upload;
