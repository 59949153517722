import { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import useTabs from 'hook/useTabs';
import CameraLayout from 'layout/CameraLayout';
import { PageHeading } from 'component/Typography';
import { Sidebar, FormContentWrapper, FormFooter } from 'layout/shared';
import { Button } from 'component/Button';
import { useTranslation } from 'react-i18next';
import { default as FormBuilder } from 'app/Form/Builder';
import { useQuery, useMutation } from 'react-query';
import { planId as planIdMap, planOption as planOptionMap } from 'constant/map';
import SentSuccessMessage from 'app/Form/Scheme/SentSuccessMessage';
import DataCheckForm from 'app/Form/Scheme/DataCheckForm';
import api from 'api';
import { useDebouncedCallback } from 'use-debounce';
import { useParams } from 'react-router';
import { Error } from 'component/Typography';
import styled from 'styled-components';
import { useMedia } from 'react-use';
import StepAction from 'component/StepAction';
import { TaiccaContext } from 'App';
import { useHistory } from 'react-router-dom';
import useUser from 'hook/useUser';

const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${(props) => props.theme.media.tablet_S} {
    width: 100%;
  }
`;
const StepBlock = styled.div`
  position: relative;
  padding-bottom: 13px;
`;
const StepHeading = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 700;
`;
const StepNumber = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.color.primary600};
`;
const ProjectSaveAndGoBack = styled.button`
  margin-right: 14px;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
`;

function FormPage(props) {
  const user = useUser();
  const { verified, email, role } = user;
  const isTablet_S = useMedia('(max-width: 551px)');
  const { tabIndex } = useContext(TaiccaContext);
  const { planId, planOption } = props;
  const { pid } = useParams();
  const [projectId, setProjectId] = useState(pid);
  const isEdit = !!projectId;
  const history = useHistory();
  useEffect(() => {
    setProjectId(pid);
  }, [pid]);

  const formQuery = useQuery(
    ['planParams', planId, planOption],
    api.planParams,
    {
      staleTime: 0,
      cacheTime: 0,
      onSuccess: (res) => {
        if (isEdit) return;
        const { formShape = {} } = res;
        const initialValue = Object.values(formShape)
          .flat()
          .reduce((accumulator, field) => {
            const isFile = field.type === 'file';
            const isImage = field.type === 'image';
            const isArray = isFile || isImage;
            return {
              ...accumulator,
              [field.name]: isArray ? [] : '',
            };
          }, {});
        setFormData({
          values: initialValue,
        });
        setSelectedTab('step1');
      },
    },
  );
  const { data = {} } = formQuery;
  const { formShape = {}, title = {} } = data;
  const formShapeList = useMemo(() => Object.values(formShape), [formShape]);
  const projectDetailQuery = useQuery(
    ['projectDetail', pid],
    api.projectDetail,
    {
      enabled: !!pid && formQuery.isSuccess,
      staleTime: Infinity,
      cacheTime: 0,
      onSuccess: (res) => {
        const initialValue = formShapeList
          .flat()
          .reduce((accumulator, field) => {
            const isFile = field.type === 'file';
            const isImage = field.type === 'image';
            const newValue = (() => {
              const value = res[field.name];
              if (isFile) {
                if (!value) return [];
                if (value.fileId === '') return [];
                return [value];
              }
              if (isImage) {
                if (!value) return [];
                const fileName = value.split('/').pop();
                return [
                  {
                    fileName: fileName,
                    fullPath: `${process.env.REACT_APP_BASE_URL}/${value}`,
                    path: value,
                  },
                ];
              }
              return value;
            })();
            return {
              ...accumulator,
              [field.name]: newValue,
            };
          }, {});
        setFormData({
          values: initialValue,
        });
        setSelectedTab('step1');
      },
    },
  );

  const code =
    role !== 'recommend'
      ? ''
      : localStorage.getItem('refferalCode') === null ||
        localStorage.getItem('refferalCode') === undefined
      ? projectDetailQuery.referral_code
      : localStorage.getItem('refferalCode');
  const getFieldInfoByName = useCallback(
    (name) => formShapeList.flat().find((f) => f.name === name) || {},
    [formShapeList],
  );

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    values: {},
  });
  const [isDirty, setIsDirty] = useState(false);
  const handleChange = useCallback(
    (val) => {
      const newFormData = {
        ...val,
        values: {
          ...val.values,
          projectSpec: t('scheme.projectSpec.format', {
            type: val.values.projectSpecType,
            time: val.values.projectSpecTime,
            episode: val.values.projectSpecEpisode,
          }),
        },
      };
      setFormData(newFormData);
      setIsDirty(true);
    },
    [t],
  );
  const flowStatus = useMemo(() => {
    return formShapeList.map((step, i) => {
      const { touched = {}, errors = {} } = formData[i] || {};
      const isTouched = !!pid || Object.keys(touched).length > 0;
      const isError = Object.keys(errors).length > 0;
      if (projectDetailQuery.isLoading || formQuery.isLoading) return 'none';
      if (!isTouched) return 'none';
      if (isError) return 'warning';
      return 'complete';
    });
  }, [
    formData,
    formShapeList,
    pid,
    projectDetailQuery.isLoading,
    formQuery.isLoading,
  ]);
  const tabOptions = useMemo(() => {
    if (pid && projectDetailQuery.isError) return [];
    const formOptions = formShapeList.map((step, i) => {
      const key = `step${i + 1}`;
      let formTitleKey = '';
      switch (planOption) {
        case planOptionMap.SERIES:
          if (planId === planIdMap.SUPPORT)
            formTitleKey = t('scheme.longFilmTvSeries.scriptHeading');
          if (planId === planIdMap.COOPERATIVE)
            formTitleKey = t('scheme.longFilmTvSeries.cooperativeHeading');
          break;
        case planOptionMap.DOCUMENTARY:
          if (planId === planIdMap.SUPPORT)
            formTitleKey = t('scheme.documentarySeries.scriptHeading');
          if (planId === planIdMap.COOPERATIVE)
            formTitleKey = t('scheme.documentarySeries.cooperativeHeading');
          break;
        case planOptionMap.ANIME:
          if (planId === planIdMap.SUPPORT)
            formTitleKey = t('scheme.animation.scriptHeading');
          if (planId === planIdMap.COOPERATIVE)
            formTitleKey = t('scheme.animation.cooperativeHeading');
          break;
        case planOptionMap.MARKETING:
          formTitleKey = t('scheme.marketingSupport.heading');
          break;
        default:
          break;
      }
      return {
        value: key,
        label: title[`${key}_title`],
        panel: (
          <FormBuilder
            formData={formData}
            onChange={handleChange}
            form={step}
            id={i}
            title={formTitleKey}
            isEdit={!!pid}
          />
        ),
        status: flowStatus[i],
      };
    });
    return [
      ...formOptions,
      {
        value: 'dataCheck',
        label: t('common.flow.docConfirmation'),
        panel: (
          <DataCheckForm formData={formData} formShapeList={formShapeList} />
        ),
        status: 'none',
      },
    ];
  }, [
    formData,
    handleChange,
    flowStatus,
    title,
    formShapeList,
    planOption,
    planId,
    projectDetailQuery.isError,
    pid,
    t,
  ]);

  const projectSaveMutation = useMutation(api.projectSave, {
    onSuccess: (res) => {
      if (!projectId) setProjectId(res.data.projectId);
    },
  });
  const debouncedProjectSave = useDebouncedCallback(
    projectSaveMutation.mutate,
    500,
  );

  const projectCommitMutation = useMutation(api.projectCommit);
  const { Tabs, TabPanels, next, prev, isFirstTab, isLastTab, setSelectedTab } =
    useTabs({
      options: tabOptions,
      align: 'right',
      hasNumber: true,
      lock: projectCommitMutation.isSuccess,
      isDisplayShowHide: true,
    });

  useEffect(() => {
    if (!isDirty) return;
    const formValuesEntries = Object.entries(formData.values);
    if (formValuesEntries.length === 0) return;
    setIsDirty(false);
    const formattedFormData = Object.fromEntries(
      formValuesEntries.map((x) => {
        const key = x[0];
        const value = x[1];
        const fieldType = getFieldInfoByName(key).type || '';
        const isFile = fieldType === 'file';
        const isImage = fieldType === 'image';
        const newValue = (() => {
          if (isFile) return !!value[0] ? value[0].fileId : '';
          if (isImage) return !!value[0] ? value[0].path : '';
          return value;
        })();
        return [key, newValue];
      }),
    );
    const formatFormDataForApi = {
      projectId: projectId, //optional, 若未帶入，則為新增
      planId: planId,
      planOption: planOption,
      code: code,
      ...formattedFormData,
    };
    debouncedProjectSave(formatFormDataForApi);
  }, [
    isDirty,
    setIsDirty,
    debouncedProjectSave,
    formData.values,
    projectId,
    planId,
    planOption,
    code,
    getFieldInfoByName,
  ]);

  const isLoading = formQuery.isLoading || projectDetailQuery.isLoading;

  const isFillComplete = flowStatus.every((status) => status === 'complete');

  return (
    <>
      <CameraLayout
        progressBlock={
          !isTablet_S ? (
            <Sidebar>
              <PageHeading>{t('header.delivery')}</PageHeading>
              {!isLoading && Tabs}
            </Sidebar>
          ) : (
            <StepBlock>
              {!projectCommitMutation.isSuccess && (
                <StepAction onClick={prev} arrow={'left'}></StepAction>
              )}
              <StepHeading>{t('header.memberApplicationPage')}</StepHeading>
            </StepBlock>
          )
        }
      >
        <FormContentWrapper>
          {isTablet_S && !projectCommitMutation.isSuccess && (
            <StepNumber>{`step ${tabIndex + 1} of ${
              tabOptions.length
            }`}</StepNumber>
          )}
          {(() => {
            if (projectDetailQuery.isError)
              return <div>{projectDetailQuery.error}</div>;
            if (isLoading) return <div>Loading...</div>;
            if (projectCommitMutation.isSuccess) return <SentSuccessMessage />;
            return TabPanels;
          })()}
        </FormContentWrapper>
        {!projectCommitMutation.isSuccess &&
          (isTablet_S ? (
            isLastTab ? (
              <FormFooter>
                <MessageBlock>
                  {projectCommitMutation.isError && (
                    <Error>{projectCommitMutation.error}</Error>
                  )}
                  <Button
                    onClick={() => {
                      projectCommitMutation.mutate({
                        projectId,
                        code,
                      });
                    }}
                    disabled={isLoading || !isFillComplete}
                    color="primary"
                    fullWidth
                  >
                    {t('common.sendForm')}
                  </Button>
                </MessageBlock>
              </FormFooter>
            ) : (
              <FormFooter>
                <Button
                  onClick={next}
                  color="primary"
                  disabled={isLoading}
                  fullWidth
                >
                  {t('common.nextStep')}
                </Button>
              </FormFooter>
            )
          ) : isLastTab ? (
            <FormFooter>
              <Button
                onClick={prev}
                color="secondary"
                disabled={isFirstTab || isLoading}
              >
                {t('common.prevStep')}
              </Button>
              <MessageBlock>
                {projectCommitMutation.isError && (
                  <Error>{projectCommitMutation.error}</Error>
                )}
                <Button
                  onClick={() => {
                    projectCommitMutation.mutate({
                      projectId,
                      code,
                    });
                  }}
                  disabled={isLoading || !isFillComplete}
                  // disabled={isLoading || !isFillComplete || true}
                  color="primary"
                >
                  {t('common.sendForm')}
                </Button>
              </MessageBlock>
            </FormFooter>
          ) : (
            <FormFooter>
              <Button
                onClick={prev}
                color="secondary"
                disabled={isFirstTab || isLoading}
              >
                {t('common.prevStep')}
              </Button>
              <div>
                <ProjectSaveAndGoBack
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  {t('common.saveAndGoBack')}
                </ProjectSaveAndGoBack>

                <Button onClick={next} color="primary" disabled={isLoading}>
                  {t('common.nextStep')}
                </Button>
              </div>
            </FormFooter>
          ))}
      </CameraLayout>
    </>
  );
}

export default FormPage;
