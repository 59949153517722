import { default as SelectBase } from 'react-select';
import color from 'constant/color';
import { memo } from 'react';

function Select(props) {
  const { options, isError, id, ...rest } = props;
  return (
    <SelectBase
      styles={{
        container: (styles) => ({ ...styles, width: '100%' }),
        placeholder: (styles) => ({
          ...styles,
          color: color.bluegray300,
          margin: 0,
          wordBreak: 'keep-all',
        }),
        input: (styles) => ({ ...styles, margin: 0 }),
        control: (styles) => ({
          ...styles,
          width: '100%',
          padding: '0 2px 0 13px',
          height: '46px',
          color: color.bluegray300,
          border: `1px solid ${isError ? color.primary500 : color.bluegray300}`,
          borderRadius: '0',
          transition: '0.3s',
          ':hover': {
            border: `1px solid ${color.bluegray500}`,
            boxShadow: 'none !important',
          },
        }),
        valueContainer: (styles) => ({ ...styles, padding: '0' }),
        singleValue: (styles) => ({
          ...styles,
          marginLeft: '0',
          marginRight: '0',
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          display: 'none',
        }),
        indicatorContainer: (styles) => ({
          ...styles,
          padding: '0',
        }),
      }}
      options={options}
      inputId={id}
      {...rest}
      value={options.find((opt) => opt.value === props.value) || ''}
    />
  );
}

export default memo(Select);
