import styled from 'styled-components/macro';

export const Text = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.color.bluegray900};
`;
const Download = styled.span`
  margin-left: 8px;
  cursor: pointer;
`;

const BlockHeading = (props) => {
  const { download, children } = props;
  return (
    <Text>
      {children}
      {download && (
        <Download dangerouslySetInnerHTML={{ __html: download }}></Download>
      )}
    </Text>
  );
};

export default BlockHeading;
