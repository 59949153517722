import styled, { css } from 'styled-components/macro';
import { FormHeading, Description } from 'component/Typography';
import { Text } from 'component/BlockHeading';
import { StyledFormControl } from 'component/FormControl';
import { StyledTable } from 'component/Table';
import { Button } from 'component/Button';
import { Action } from 'component/SendCard';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 920px;
  background: ${(props) => props.theme.color.bluegray000};
  position: relative;
  z-index: 3;
  min-height: calc(100vh - 80px - 58px);
  ${(props) => props.theme.media.tablet_S} {
    flex: 1 1 auto;
  }

  a:not(${Button}):not(${Action}) {
    color: ${(props) => props.theme.color.primary600};
    text-decoration: underline;
  }
  ${Button} {
    text-decoration: none;
  }
`;

const Sidebar = styled.div`
  position: sticky;
  inset: calc(58px + 80px) auto auto auto;
  padding-right: 80px;
  text-align: right;
  z-index: 2;
  ${(props) => props.theme.media.laptop_L} {
    padding-right: 37px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding-right: 20px;
  }
  ${(props) => props.theme.media.tablet_S} {
    padding-right: 0px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  ${(props) =>
    props.align === 'left' &&
    css`
      text-align: left;
      padding-right: 0;
      padding-left: 72px;
      ${(props) => props.theme.media.laptop_L} {
        padding-left: 50px;
      }
      ${(props) => props.theme.media.laptop_M} {
        padding-left: 32px;
      }
      ${(props) => props.theme.media.tablet_S} {
        padding-left: 0px;
      }
    `};
`;
const FormContentWrapper = styled.div`
  flex: auto;
  padding: 42px 48px 64px;
  position: relative;
  ${(props) => props.theme.media.laptop_M} {
    padding: 42px 32px 54px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 30px 20px 50px;
  }

  ${FormHeading} {
    margin-bottom: 32px;
    ${(props) => props.theme.media.tablet_S} {
      margin-bottom: 15px;
    }
  }
  ${Description} {
    margin-bottom: 24px;
    ${(props) => props.theme.media.laptop_M} {
      margin-bottom: 22px;
    }
    ${(props) => props.theme.media.mobile_M} {
      margin-bottom: 18px;
    }
  }

  ${Text} {
    margin-bottom: 8px;
  }

  ${StyledFormControl} {
    margin-bottom: 24px;
    max-width: 450px;
    ${(props) => props.theme.media.laptop_M} {
      margin-bottom: 22px;
    }
    ${(props) => props.theme.media.mobile_M} {
      margin-bottom: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${StyledTable} {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const FormFooter = styled.div`
  padding: 0 48px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  inset: auto auto 0 auto;
  background-color: ${(props) => props.theme.color.bluegray050};
  ${(props) => props.theme.media.laptop_M} {
    padding: 0 32px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 0 20px;
  }
`;
const TopButton = styled(Button)`
  position: absolute;
  inset: 24px -12px auto auto;
  ${(props) => props.theme.media.tablet_S} {
    inset: auto 0 calc(100% + 16px) auto;
  }
  ${(props) => props.theme.media.mobile_L} {
    inset: auto 0 calc(100% + 12px) auto;
  }
  ${(props) => props.theme.media.mobile_S} {
    inset: auto 0 calc(100% + 14px) auto;
  }
`;

export { FormContainer, FormContentWrapper, Sidebar, FormFooter, TopButton };
