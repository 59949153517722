import { Menu, Content, FormContainer, Footer } from 'layout/shared';
import styled from 'styled-components/macro';
import { useMedia } from 'react-use';

const RightBottomBlock = styled.div`
  top: 100vh;
  position: sticky;
  display: flex;

  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
`;
const LittleCube = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  transition: 0.3s;
  &:hover {
    background: ${(props) => props.theme.color.bluegray000};
  }
`;
const Diamond = styled.div`
  position: relative;
  width: 50%;
  height: 20px;
  ${(props) => props.theme.media.laptop_M} {
    height: 16px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 17px;
    inset: auto 1px 0 0;
    clip-path: polygon(9.5% 0, 100% 0%, 100% 100%, 1% 100%);
    background: ${(props) => props.theme.color.primary500};
    transform: translate(0, -1px);
    z-index: -1;
    ${(props) => props.theme.media.laptop_M} {
      height: 14px;
      clip-path: polygon(9.5% 0, 100% 0%, 100% 100%, 1.5% 100%);
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 19px;
    inset: auto 0 0 0;
    clip-path: polygon(9% 0, 100% 0%, 100% 100%, 0% 100%);
    background: #fff;
    z-index: -2;
    ${(props) => props.theme.media.laptop_M} {
      height: 16px;
    }
  }
`;
const Rectangle = styled.div`
  width: 100%;
  height: 20px;
  border: solid 1px ${(props) => props.theme.color.bluegray000};
  ${(props) => props.theme.media.laptop_M} {
    height: 15px;
  }
`;
const LittleCubeContainer = styled.div`
  position: absolute;
  inset: auto 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  width: 400px;
  ${(props) => props.theme.media.tablet_S} {
    display: none;
  }

  ${LittleCube} {
    margin-left: -1px;
    margin-top: -1px;
  }

  ${Diamond} {
    margin-top: -1px;
  }

  ${Rectangle} {
    margin-top: -1px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  margin: 80px auto 0;
  ${(props) => props.theme.media.tablet_S} {
    flex-direction: column-reverse;
  }
`;
const ProgressContainer = styled.div`
  padding-top: 58px;
  flex: 1 0.5 auto;
  position: relative;
  width: 400px;
  color: ${(props) => props.theme.color.bluegray000};
  ${(props) => props.theme.media.tablet_S} {
    padding-top: 0px;
    width: 100%;
  }
`;

function FolderLayout(props) {
  const { progressBlock, children } = props;
  const isMobileL = useMedia('(max-width: 425px)');

  return (
    <>
      <Menu />
      <Content>
        <Wrapper>
          <FormContainer isMobileL={isMobileL}>{children}</FormContainer>
          <ProgressContainer>
            {progressBlock}
            <RightBottomBlock>
              <LittleCubeContainer>
                <Diamond />
                <Rectangle />
                {Array(126)
                  .fill()
                  .map((_, i) => (
                    <LittleCube key={i} />
                  ))}
              </LittleCubeContainer>
            </RightBottomBlock>
          </ProgressContainer>
        </Wrapper>
      </Content>
      <Footer />
    </>
  );
}

export default FolderLayout;
