import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from 'locale/zh-TW';
import en from 'locale/en-US';

i18n.use(initReactI18next).init({
  resources: {
    'zh-TW': { translation: zh },
    'en-US': { translation: en },
  },
  lng: localStorage.getItem('Locale') || 'zh-TW',
  fallbackLng: 'zh-TW',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});
