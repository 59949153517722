import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 62px;
  padding: 0 20px;
  color: ${(props) => props.theme.color.bluegray000};
  font-size: 12px;
  background-color: ${(props) => props.theme.color.bluegray900};
`;

function Footer() {
  return (
    <Container>
      Copyright© 2021 TAICCA (Taiwan Creative Content Agency) . All rights
      reserved.
    </Container>
  );
}

export { Footer };
