import { FormHeading } from 'component/Typography';
import BlockHeading from 'component/BlockHeading';
import Table from 'component/Table';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { DownloadRow } from 'layout/shared/FormRow';
import { useTranslation } from 'react-i18next';
import React from 'react';
import storageKey from 'constant/storageKey';

function DownloadCenter() {
  const { t } = useTranslation();
  const locale = localStorage.getItem(storageKey.LOCALE);
  return (
    <>
      <FormHeading>{t('memberCenter.download.heading')}</FormHeading>
      <BlockHeading
        download={
          locale === 'zh-TW'
            ? `<a href="https://drive.google.com/drive/folders/1fFrfpJOjxRmm5yDJjXytpIqjjXs7FSWB">${t(
                'memberApply.common.download',
              )}</a>`
            : `<a href="https://drive.google.com/drive/folders/1fFrfpJOjxRmm5yDJjXytpIqjjXs7FSWB">${t(
                'memberApply.common.download',
              )}</a>`
        }
      >
        {t('memberCenter.download.planAndSupport')}
      </BlockHeading>
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: '100%',
          },
        ]}
        dataSource={
          [
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://drive.google.com/uc?export=download&id=1kSijBKWooZwRRZ55UNTYnnfqaspiPSJr"
            //     >
            //       <span>{t('memberCenter.download.regulationsChinese')}</span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://drive.google.com/uc?export=download&id=1cMSyaZ1ZyXChp5VFY6T2y2IOtEF4iQKf"
            //     >
            //       <span>{t('memberCenter.download.rulesChinese')}</span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1X8E9jjzrPMp0oThPy-k4AhweOmz5o6Iq/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.planWritingInstructions.longFilmTvSeriesChinese',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1iO4lI3XtlT3ZryhmWpWO5Tus6Iup4hEC/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.planWritingInstructions.documentarySeriesChinese',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/15S3wzG8UNKssTy1xrGorZscQgYT0pZpj/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.planWritingInstructions.animationChinese',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // process.env.REACT_APP_ENV === 'development' && {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/17R60j4vt1kiTBDRqmPSgiUzLaw84jb4L/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.planWritingInstructions.normalEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1Aj_-dLGH01aM_S97yR1imQprYu3xGNO8/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.authorizationOfOriginalWork',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1PVeaNsLZxGp9nja3910xALewUEJBO78P/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.originalScreenplayAdaptationAuthorizationLetter',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1v1NpZb_SEYSsvCTSyzZ5eZNH3HraVzpB/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.contentDevelopmentWorkRightsStatement',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1ZlCGgFgvyM08lT8JS8HdZLEDsX92qpRS/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.mainCharactersOfTheDocumentary',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1XgQOzneH--QVqVCFu5lDtx2stwIDwNJi/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.authorizationOfOriginalWorkEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1KZiGayJxTxbXUCOyL35_ni9M7a84z-PD/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.originalScreenplayAdaptationAuthorizationLetterEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1Er6dxQNRhP7E3OToUKPqVnBb0ADgt-UM/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.contentDevelopmentWorkRightsStatementEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1Dq_cZNLqtinKzXUymVGiMD_tHkmmR5s9/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.contentDevelopment.mainCharactersOfTheDocumentaryEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
          ]
        }
      />
      <BlockHeading
        download={
          locale === 'zh-TW'
            ? `<a href="https://drive.google.com/drive/u/0/folders/1Ch8x7-RrZWz4v2y92VmZeSwOSmqfj6YQ">${t(
                'memberApply.common.download',
              )}</a>`
            : `<a href="https://drive.google.com/drive/u/0/folders/1Ch8x7-RrZWz4v2y92VmZeSwOSmqfj6YQ">${t(
                'memberApply.common.download',
              )}</a>`
        }
      >
        {t('memberCenter.download.plan')}
      </BlockHeading>
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: '100%',
          },
        ]}
        dataSource={
          [
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://drive.google.com/uc?export=download&id=1g9eKRC2MgC6NzHoTKpZC6UwKpH0qx9Z3"
            //     >
            //       <span>{t('memberCenter.download.ticpRuleChinese')}</span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/15S3wzG8UNKssTy1xrGorZscQgYT0pZpj/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.planWritingInstructions.normalChinese',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // process.env.REACT_APP_ENV === 'development' && {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/19fljdx3klzGMKf63lEr5SmW3zXzDGxjB/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.planWritingInstructions.normalEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1qXUFavK_TOw6Gpi-nywT4Qjg26wWsULU/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.authorizationOfOriginalWork',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1bIKTEnBvXXKUSxG2e1sUuSEuKLsMgx39/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.originalScreenplayAdaptationAuthorizationLetter',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1h5-ggzgD27NdrFsEsSGj_OXtu3E8h7a1/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.contentDevelopmentWorkRightsStatement',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1dXE21QaqV354nJ_LsXzDTqVfO5PsKmY0/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.mainCharactersOfTheDocumentary',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1yvn0kPkpx3q_sMkQpuAm5GgBPSCfx20S/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.authorizationOfOriginalWorkEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/18UhY1vsXgVJ4GXeTZJeCjzs9Vk01dcnU/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.originalScreenplayAdaptationAuthorizationLetterEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1YA20rtLIgyT9dA13QEPm-gCP_QIGft9O/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.contentDevelopmentWorkRightsStatementEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
            // {
            //   name: (
            //     <DownloadRow
            //       as="a"
            //       target="_blank"
            //       rel="noreferrer noopener"
            //       href="https://docs.google.com/document/d/1CgN40bh8t6qfTySQiMqfzxCxHbyIn4Z6/export?format=doc"
            //     >
            //       <span>
            //         {t(
            //           'memberCenter.download.globalCooperation.mainCharactersOfTheDocumentaryEnglish',
            //         )}
            //       </span>
            //       <FontAwesomeIcon icon={['fas', 'download']} />
            //     </DownloadRow>
            //   ),
            // },
          ]
        }
      />
    </>
  );
}

export { DownloadCenter };
