import { FormHeading, Description } from 'component/Typography';
import { Button } from 'component/Button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useUser from 'hook/useUser';

const Desc = styled(Description)`
  margin-bottom: 32px;
  white-space: pre-line;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

function SentSuccessMessage() {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <>
      <FormHeading>{t('common.flow.yourApplicationHasSent')}</FormHeading>
      <Desc>
        {t('common.flow.pleaseNoteYoureEmailToRelevantReviewNotification', {
          userEmail: user.email,
        })}
      </Desc>
      <ButtonContainer>
        <Button as={Link} to="/member" color="primary">
          {t('common.goSchemeHome')}
        </Button>
        <Button as={Link} to="/send-scheme" color="secondary">
          {t('common.flow.applyOneMore')}
        </Button>
      </ButtonContainer>
    </>
  );
}

export default SentSuccessMessage;
