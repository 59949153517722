import styled from 'styled-components/macro';
import { FormHeading } from 'component/Typography';
import Table from 'component/Table';
import { Divider } from 'component/Divider';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import api from 'api';
import { DownloadRow } from 'layout/shared/FormRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMedia } from 'react-use';

const Action = styled.div`
  display: flex;
  gap: 20px;
  position: absolute;
  inset: 42px 48px auto auto;
  ${(props) => props.theme.media.laptop_M} {
    inset: 21px 32px auto auto;
    flex-direction: column;
    gap: 10px;
  }
  ${(props) => props.theme.media.tablet_L} {
    inset: 20px 20px auto auto;
    gap: 8px;
  }
  ${(props) => props.theme.media.tablet_S} {
    inset: 15px 20px auto auto;
  }
`;
const Link = styled.span`
  display: inline-block;
  padding: 8px 16px;
  color: ${(props) => props.theme.color.bluegray900}!important;
  font-size: 14px;
  box-shadow: 0px 5px 10px #43434b1a;
  text-decoration: none !important;
  transition: 0.2s;
  ${(props) => props.theme.media.laptop_M} {
    text-align: center;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 12px;
  }
  ${(props) => props.theme.media.tablet_S} {
    padding: 5px 10px;
  }
  &:hover {
    color: ${(props) => props.theme.color.bluegray000}!important;
    background: ${(props) => props.theme.color.primary600};
  }
`;

function MemberInfo() {
  const { t } = useTranslation();
  const isTabletM = useMedia('(max-width: 678px)');
  const userInfoQuery = useQuery('userInfo', api.userInfo);
  const { data, download_all } = userInfoQuery.data;
  const {
    applicant,
    capital,
    companyIntroduction,
    contactAddress,
    contactPerson,
    creationDate,
    email,
    fax,
    numberOfEmployees,
    personInCharge,
    registrantAddress,
    tax,
    verified,
  } = data;
  const { full: contactAddressFull } = contactAddress;
  const {
    department: contactPersonDepartment,
    email: contactPersonEmail,
    name: contactPersonName,
    phone: contactPersonPhone,
    tel: contactPersonTel,
  } = contactPerson;
  const {
    email: personInChargeEmail,
    name: personInChargeName,
    phone: personInChargePhone,
    tel: personInChargeTel,
  } = personInCharge;
  const { full: registrantAddressFull } = registrantAddress;
  return (
    <>
      <FormHeading>{t('memberCenter.memberInfo.heading')}</FormHeading>
      <Action>
        <Link
          as="a"
          href="mailto:coproduction@taicca.tw?subject=需修改會員資料"
        >
          {t('memberCenter.memberInfo.reviseContact')}
        </Link>
        <Link as="a" href={download_all}>
          {t('memberCenter.memberInfo.downloadAll')}
        </Link>
      </Action>
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: isTabletM ? '130px' : '192px',
          },
          {
            dataIndex: 'content',
            width: '100%',
          },
        ]}
        dataSource={[
          {
            name: t('common.flow.loginMailbox'),
            content: email,
          },
          { name: t('common.flow.password'), content: '*******' },
        ]}
      />
      <Divider />
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: isTabletM ? '130px' : '192px',
          },
          {
            dataIndex: 'content',
            width: '100%',
          },
        ]}
        dataSource={[
          {
            name: t('memberCenter.memberInfo.companyName'),
            content: applicant,
          },
          { name: t('common.flow.guiNumber'), content: tax },
          {
            name: t('common.flow.capital'),
            content: t('memberCenter.memberInfo.capital', {
              value: Number(capital).toLocaleString(),
            }), // i18n
          },
          {
            name: t('common.flow.numberOfEmployees'),
            content: `${numberOfEmployees} ${t(
              'memberCenter.memberInfo.people',
            )}`,
          }, // i18n
          {
            name: t('common.flow.established'),
            content: !!creationDate
              ? dayjs(creationDate).format('YYYY.MM.DD')
              : '',
          },
          {
            name: t('common.flow.companyProfile'),
            content: companyIntroduction,
          },
          {
            name: t('common.flow.registeredAddress'),
            content: registrantAddressFull,
          },
          {
            name: t('common.flow.personInChargeName'),
            content: personInChargeName,
          },
          {
            name: t('common.flow.personInChargeTelephone'),
            content: personInChargeTel,
          },
          {
            name: t('common.flow.personInChargePhone'),
            content: personInChargePhone,
          },
          {
            name: t('common.flow.personInChargeEmail'),
            content: personInChargeEmail,
          },
          { name: t('common.flow.fax'), content: fax },
          {
            name: t('common.flow.contactAddress'),
            content: contactAddressFull,
          },
          {
            name: t('common.flow.contactPersonName'),
            content: contactPersonName,
          },
          {
            name: t('common.flow.contactDepartment'),
            content: contactPersonDepartment,
          },
          {
            name: t('common.flow.contactDepartmentTelephone'),
            content: contactPersonTel,
          },
          {
            name: t('common.flow.contactDepartmentPhone'),
            content: contactPersonPhone,
          },
          {
            name: t('common.flow.contactDepartmentEmail'),
            content: contactPersonEmail,
          },
        ]}
      />
      {verified && (
        <Table
          columns={[
            {
              dataIndex: 'name',
              width: '100%',
            },
          ]}
          dataSource={[
            {
              name: (
                <DownloadRow href={data.registrationDocuments}>
                  <span>{t('memberApply.flow1.setRegisterDoc')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow href={data.nonMainlandCapitalDeclaration}>
                  <span>{t('memberApply.flow1.statement')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow href={data.balanceSheetForThePastYear}>
                  <span>{t('memberApply.flow1.balanceSheet')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow href={data.incomeStatementForThePastYear}>
                  <span>{t('memberApply.flow1.incomeStatement')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow href={data.cashFlowStatementForThePastYear}>
                  <span>{t('memberApply.flow1.cashFlowStatement')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow href={data.financialStatementOrFinancialVisa}>
                  <span>{t('memberApply.flow1.financialDoc')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow
                  href={
                    data.creditReportOfUnitedCreditInformationCenterForThePastSixMonths
                  }
                >
                  <span>{t('memberApply.flow1.creditReport')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow href={data.noRefundRecordForThePastSixMonths}>
                  <span>{t('memberApply.flow1.noRefundProof')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            {
              name: (
                <DownloadRow
                  href={data.certificateOfNoIllegalTaxOwingForThePastSixMonths}
                >
                  <span>{t('memberApply.flow1.noViolationsCertificate')}</span>
                  <FontAwesomeIcon icon={['fas', 'download']} />
                </DownloadRow>
              ),
            },
            // TODO: 應改成推薦會員就不顯示這些
            ...(true
              ? [
                  {
                    name: (
                      <DownloadRow href={data.productionExperience}>
                        <span>{t('memberApply.flow2.productionExp')}</span>
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </DownloadRow>
                    ),
                  },
                  {
                    name: (
                      <DownloadRow href={data.awardRecord}>
                        <span>{t('memberApply.flow2.awardRecord')}</span>
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </DownloadRow>
                    ),
                  },
                  {
                    name: (
                      <DownloadRow href={data.ventureCapitalExperience}>
                        <span>{t('memberApply.flow2.vcRecord')}</span>
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </DownloadRow>
                    ),
                  },
                  {
                    name: (
                      <DownloadRow
                        href={
                          data.intellectualPropertyRightsDevelopmentExperience
                        }
                      >
                        <span>{t('memberApply.flow2.ipRecord')}</span>
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </DownloadRow>
                    ),
                  },
                  {
                    name: (
                      <DownloadRow href={data.internationalMarket}>
                        <span>{t('memberApply.flow3.intMarket')}</span>
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </DownloadRow>
                    ),
                  },
                  {
                    name: (
                      <DownloadRow href={data.internationalJointVenture}>
                        <span>{t('memberApply.flow3.intJointVenture')}</span>
                        <FontAwesomeIcon icon={['fas', 'download']} />
                      </DownloadRow>
                    ),
                  },
                ]
              : []),
          ]}
        />
      )}
    </>
  );
}

export { MemberInfo };
