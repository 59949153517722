import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Arrow = styled.span`
  display: inline-block;
  position: absolute;
  font-size: 18px;
  cursor: pointer;
`;

function StepAction(props) {
  const { arrow, onClick } = props;
  return (
    <>
      <Arrow onClick={onClick}>
        {arrow === 'right' ? (
          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
        ) : null}
        {arrow === 'left' ? (
          <FontAwesomeIcon icon={['fas', 'arrow-left']} />
        ) : null}
      </Arrow>
    </>
  );
}

export default StepAction;
