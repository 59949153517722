import FormControl from 'component/FormControl';
import PasswordInput from 'component/Input/PasswordInput';
import { FormHeading } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from 'api';
import { useMutation } from 'react-query';
import { FormContentWrapper, FormFooter } from 'layout/shared';
import { Button } from 'component/Button';
import { Error } from 'component/Typography';
import styled from 'styled-components';

const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
function ChangePassword() {
  const { t } = useTranslation();
  const changePasswordMutation = useMutation(api.changePassword);
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(t('common.validation.required')),
      newPassword: Yup.string().required(t('common.validation.required')),
      confirmNewPassword: Yup.string().required(
        t('common.validation.required'),
      ),
    }),
    onSubmit: (values) => {
      changePasswordMutation.mutate(values);
    },
  });
  const { touched, errors, submitForm } = formik;
  return (
    <>
      <FormContentWrapper>
        {changePasswordMutation.isSuccess ? (
          <div>{t('memberCenter.changePassword.changeSuccess')}</div>
        ) : (
          <>
            <FormHeading>
              {t('memberCenter.changePassword.heading')}
            </FormHeading>
            <FormControl
              isRequired
              label={t('memberCenter.changePassword.oldPassword')}
              placement="top-left"
              isError={!!(touched.oldPassword && errors.oldPassword)}
              errorMessage={errors.oldPassword}
            >
              <PasswordInput
                id="oldPassword"
                {...formik.getFieldProps('oldPassword')}
              />
            </FormControl>
            <FormControl
              isRequired
              label={t('memberCenter.changePassword.newPassword')}
              placement="top-left"
              isError={!!(touched.newPassword && errors.newPassword)}
              errorMessage={errors.newPassword}
            >
              <PasswordInput
                id="newPassword"
                {...formik.getFieldProps('newPassword')}
              />
            </FormControl>
            <FormControl
              isRequired
              label={t('memberCenter.changePassword.confirm')}
              placement="top-left"
              isError={
                !!(touched.confirmNewPassword && errors.confirmNewPassword)
              }
              errorMessage={errors.confirmNewPassword}
            >
              <PasswordInput
                id="confirmNewPassword"
                {...formik.getFieldProps('confirmNewPassword')}
              />
            </FormControl>
          </>
        )}
      </FormContentWrapper>
      <FormFooter>
        <div />
        <MessageBlock>
          {changePasswordMutation.isError && (
            <Error>{changePasswordMutation.error}</Error>
          )}
          <Button onClick={submitForm} color="primary">
            {t('common.confirmModify')}
          </Button>
        </MessageBlock>
      </FormFooter>
    </>
  );
}

export { ChangePassword };
