import FormControl from 'component/FormControl';
import Input, { TextField } from 'component/Input/Input';
import { FormHeading } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormRow } from 'layout/shared';
import styled from 'styled-components';

const TelFormRow = styled(FormRow)`
  ${TextField} {
    &:nth-child(1) {
      width: 24%;
    }
    &:nth-child(2) {
      width: 38%;
    }
    &:nth-child(3) {
      width: 38%;
    }
  }
`;
function Flow2() {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { touched, errors } = formik;
  return (
    <>
      <FormHeading>{t('common.flow.contactInfo')}</FormHeading>
      <FormControl
        isRequired
        label={t('common.flow.personInChargeName')}
        placement="top-left"
        isError={
          !!(
            touched.personInCharge &&
            errors.personInCharge &&
            touched.personInCharge.name &&
            errors.personInCharge.name
          )
        }
        errorMessage={errors.personInCharge && errors.personInCharge.name}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="personInCharge.name"
          {...formik.getFieldProps('personInCharge.name')}
        />
      </FormControl>
      <FormControl
        label={t('common.flow.personInChargeTelephone')}
        placement="top-left"
        errorMessage={
          (touched?.personInCharge?.tel?.area &&
            errors?.personInCharge?.tel?.area) ||
          (touched?.personInCharge?.tel?.body &&
            errors?.personInCharge?.tel?.body) ||
          (touched?.personInCharge?.tel?.extension &&
            errors?.personInCharge?.tel?.extension)
        }
      >
        <TelFormRow>
          <Input
            placeholder={t('placeholder.telArea')}
            id="personInCharge.tel.area"
            isError={
              !!(
                touched?.personInCharge?.tel?.area &&
                errors?.personInCharge?.tel?.area
              )
            }
            {...formik.getFieldProps('personInCharge.tel.area')}
          />
          <Input
            placeholder={t('placeholder.telBody')}
            id="personInCharge.tel.body"
            isError={
              !!(
                touched?.personInCharge?.tel?.body &&
                errors?.personInCharge?.tel?.body
              )
            }
            {...formik.getFieldProps('personInCharge.tel.body')}
          />
          <Input
            placeholder={t('placeholder.telExtension')}
            id="personInCharge.tel.extension"
            isError={
              !!(
                touched?.personInCharge?.tel?.extension &&
                errors?.personInCharge?.tel?.extension
              )
            }
            {...formik.getFieldProps('personInCharge.tel.extension')}
          />
        </TelFormRow>
      </FormControl>
      <FormControl
        label={t('common.flow.personInChargePhone')}
        placement="top-left"
        isError={
          !!(
            touched.personInCharge &&
            errors.personInCharge &&
            touched.personInCharge.phone &&
            errors.personInCharge.phone
          )
        }
        errorMessage={errors.personInCharge && errors.personInCharge.phone}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="personInCharge.phone"
          {...formik.getFieldProps('personInCharge.phone')}
        />
      </FormControl>
      <FormControl
        label={t('common.flow.personInChargeEmail')}
        placement="top-left"
        isError={
          !!(
            touched.personInCharge &&
            errors.personInCharge &&
            touched.personInCharge.email &&
            errors.personInCharge.email
          )
        }
        errorMessage={errors.personInCharge && errors.personInCharge.email}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="personInCharge.email"
          {...formik.getFieldProps('personInCharge.email')}
        />
      </FormControl>
      <FormControl
        label={t('common.flow.fax')}
        placement="top-left"
        errorMessage={
          (touched?.fax?.area && errors?.fax?.area) ||
          (touched?.fax?.body && errors?.fax?.body) ||
          (touched?.fax?.extension && errors?.fax?.extension)
        }
      >
        <TelFormRow>
          <Input
            placeholder={t('placeholder.telArea')}
            id="fax.area"
            isError={!!(touched?.fax?.area && errors?.fax?.area)}
            {...formik.getFieldProps('fax.area')}
          />
          <Input
            placeholder={t('placeholder.telBody')}
            id="fax.body"
            isError={!!(touched?.fax?.body && errors?.fax?.body)}
            {...formik.getFieldProps('fax.body')}
          />
          <Input
            placeholder={t('placeholder.telExtension')}
            id="fax.extension"
            isError={!!(touched?.fax?.extension && errors?.fax?.extension)}
            {...formik.getFieldProps('fax.extension')}
          />
        </TelFormRow>
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.contactPersonName')}
        placement="top-left"
        isError={
          !!(
            touched.contactPerson &&
            errors.contactPerson &&
            touched.contactPerson.name &&
            errors.contactPerson.name
          )
        }
        errorMessage={errors.contactPerson && errors.contactPerson.name}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="contactPerson.name"
          {...formik.getFieldProps('contactPerson.name')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.contactDepartment')}
        placement="top-left"
        isError={
          !!(
            touched.contactPerson &&
            errors.contactPerson &&
            touched.contactPerson.department &&
            errors.contactPerson.department
          )
        }
        errorMessage={errors.contactPerson && errors.contactPerson.department}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="contactPerson.department"
          {...formik.getFieldProps('contactPerson.department')}
        />
      </FormControl>
      <FormControl
        label={t('common.flow.contactDepartmentTelephone')}
        placement="top-left"
        isRequired
        errorMessage={
          (touched?.contactPerson?.tel?.area &&
            errors?.contactPerson?.tel?.area) ||
          (touched?.contactPerson?.tel?.body &&
            errors?.contactPerson?.tel?.body) ||
          (touched?.contactPerson?.tel?.extension &&
            errors?.contactPerson?.tel?.extension)
        }
      >
        <TelFormRow>
          <Input
            placeholder={t('placeholder.telArea')}
            id="contactPerson.tel.area"
            isError={
              !!(
                touched?.contactPerson?.tel?.area &&
                errors?.contactPerson?.tel?.area
              )
            }
            {...formik.getFieldProps('contactPerson.tel.area')}
          />
          <Input
            placeholder={t('placeholder.telBody')}
            id="contactPerson.tel.body"
            isError={
              !!(
                touched?.contactPerson?.tel?.body &&
                errors?.contactPerson?.tel?.body
              )
            }
            {...formik.getFieldProps('contactPerson.tel.body')}
          />
          <Input
            placeholder={t('placeholder.telExtension')}
            id="contactPerson.tel.extension"
            isError={
              !!(
                touched?.contactPerson?.tel?.extension &&
                errors?.contactPerson?.tel?.extension
              )
            }
            {...formik.getFieldProps('contactPerson.tel.extension')}
          />
        </TelFormRow>
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.contactDepartmentPhone')}
        placement="top-left"
        isError={
          !!(
            touched.contactPerson &&
            errors.contactPerson &&
            touched.contactPerson.phone &&
            errors.contactPerson.phone
          )
        }
        errorMessage={errors.contactPerson && errors.contactPerson.phone}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="contactPerson.phone"
          {...formik.getFieldProps('contactPerson.phone')}
        />
      </FormControl>
      <FormControl
        isRequired
        label={t('common.flow.contactDepartmentEmail')}
        placement="top-left"
        isError={
          !!(
            touched.contactPerson &&
            errors.contactPerson &&
            touched.contactPerson.email &&
            errors.contactPerson.email
          )
        }
        errorMessage={errors.contactPerson && errors.contactPerson.email}
      >
        <Input
          placeholder={t('common.pleaseEnter')}
          id="contactPerson.email"
          {...formik.getFieldProps('contactPerson.email')}
        />
      </FormControl>
    </>
  );
}

export { Flow2 };
