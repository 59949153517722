import React from 'react';
import styled from 'styled-components/macro';
import { TextField, TextInput } from './Input';
import { memo } from 'react';

const TextareaField = styled(TextField)`
  height: 144px;
  align-items: stretch;
`;
const TextareaInput = styled(TextInput)`
  resize: none;
`;
function Textarea({ isError, ...props }) {
  return (
    <TextareaField isError={isError}>
      <TextareaInput as={'textarea'} {...props} />
    </TextareaField>
  );
}

export default memo(Textarea);
