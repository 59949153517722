import { useState, useMemo, useContext } from 'react';
import CameraLayout from 'layout/CameraLayout';
import { Sidebar, FormContentWrapper, FormFooter } from 'layout/shared';
import * as MemberApplicationForm from 'app/Form/MemberApplication';
import { PageHeading } from 'component/Typography';
import useTabs from 'hook/useTabs';
import { Button } from 'component/Button';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useMutation } from 'react-query';
import { Error } from 'component/Typography';
import styled from 'styled-components';
import { useMedia } from 'react-use';
import StepAction from 'component/StepAction';
import { TaiccaContext } from 'App';

const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const StepBlock = styled.div`
  position: relative;
  padding-bottom: 13px;
`;
const StepHeading = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 700;
`;
const StepNumber = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.color.primary600};
`;

function RecommendMemberApplicationPage() {
  const { t } = useTranslation();
  const isTablet_S = useMedia('(max-width: 551px)');
  const { tabIndex } = useContext(TaiccaContext);
  const recommendMemberApplyMutation = useMutation(api.recommendMemberApply);
  const [formData, setFormData] = useState({
    values: {
      registrationDocuments: [],
      nonMainlandCapitalDeclaration: [],
      balanceSheetForThePastYear: [],
      incomeStatementForThePastYear: [],
      cashFlowStatementForThePastYear: [],
      financialStatementOrFinancialVisa: [],
      creditReportOfUnitedCreditInformationCenterForThePastSixMonths: [],
      noRefundRecordForThePastSixMonths: [],
      certificateOfNoIllegalTaxOwingForThePastSixMonths: [],
      hasSubsidy: undefined,
      subsidiesInThePastThreeYears: [],
    },
  });
  const flow1Status = useMemo(() => {
    if (!formData.flow1) return 'none';
    const { touched = {}, errors = {} } = formData.flow1;
    if (Object.keys(touched).length === 0) return 'none';
    if (Object.keys(touched).length > 0 && Object.keys(errors).length > 0)
      return 'warning';
    return 'complete';
  }, [formData.flow1]);

  const flow2Status = useMemo(() => {
    if (formData.values.hasSubsidy === undefined) return 'none';
    if (formData.values.hasSubsidy) {
      if (formData.values.subsidiesInThePastThreeYears.length === 0)
        return 'warning';
      if (
        formData.values.subsidiesInThePastThreeYears.filter((subsidy) => {
          const unFilledField =
            Object.values(subsidy).findIndex((value) => value === '') !== -1;
          return unFilledField;
        }).length > 0
      )
        return 'warning';
    }
    return 'complete';
  }, [
    formData.values.hasSubsidy,
    formData.values.subsidiesInThePastThreeYears,
  ]);

  const isFillComplete =
    flow1Status === 'complete' && flow2Status === 'complete';

  const tabOptions = useMemo(
    () => [
      {
        value: 'tab1',
        label: t('tab.memberApply.needPrepare'),
        panel: (
          <MemberApplicationForm.Flow1
            formData={formData}
            onChange={setFormData}
          />
        ),
        status: flow1Status,
      },
      {
        value: 'tab2',
        label: t('tab.memberApply.threeYearsSubsidizeSituation'),
        panel: (
          <MemberApplicationForm.Flow4
            formData={formData}
            onChange={setFormData}
          />
        ),
        status: flow2Status,
      },
      {
        value: 'tab3',
        label: t('tab.common.docConfirmation'),
        panel: (
          <MemberApplicationForm.Flow5
            formData={formData}
            onChange={setFormData}
          />
        ),
        status: 'none',
      },
    ],
    [t, formData, setFormData, flow1Status, flow2Status],
  );
  const { Tabs, TabPanels, next, prev, isFirstTab, isLastTab } = useTabs({
    options: tabOptions,
    align: 'right',
    hasNumber: true,
    lock: recommendMemberApplyMutation.isSuccess,
  });

  return (
    <CameraLayout
      progressBlock={
        !isTablet_S ? (
          <Sidebar align="right">
            <PageHeading>{t('header.memberApplicationPage')}</PageHeading>
            {Tabs}
          </Sidebar>
        ) : (
          <StepBlock>
            <StepAction onClick={prev} arrow={'left'}></StepAction>
            <StepHeading>{t('header.memberApplicationPage')}</StepHeading>
          </StepBlock>
        )
      }
    >
      <FormContentWrapper>
        {isTablet_S && (
          <StepNumber>{`step ${tabIndex + 1} of ${
            tabOptions.length
          }`}</StepNumber>
        )}
        {recommendMemberApplyMutation.isSuccess ? (
          <MemberApplicationForm.Flow6 />
        ) : (
          TabPanels
        )}
      </FormContentWrapper>
      {!recommendMemberApplyMutation.isSuccess && !isTablet_S && (
        <FormFooter>
          {
            <Button onClick={prev} color="secondary" disabled={isFirstTab}>
              {t('common.prevStep')}
            </Button>
          }
          {!isLastTab ? (
            <Button onClick={next} color="primary">
              {t('common.nextStep')}
            </Button>
          ) : (
            <MessageBlock>
              {recommendMemberApplyMutation.isError && (
                <Error>{recommendMemberApplyMutation.error}</Error>
              )}

              <Button
                onClick={() => {
                  const fileFieldNameArray = [
                    'registrationDocuments',
                    'nonMainlandCapitalDeclaration',
                    'balanceSheetForThePastYear',
                    'incomeStatementForThePastYear',
                    'cashFlowStatementForThePastYear',
                    'financialStatementOrFinancialVisa',
                    'creditReportOfUnitedCreditInformationCenterForThePastSixMonths',
                    'noRefundRecordForThePastSixMonths',
                    'certificateOfNoIllegalTaxOwingForThePastSixMonths',
                    'productionExperience',
                    'awardRecord',
                    'ventureCapitalExperience',
                    'intellectualPropertyRightsDevelopmentExperience',
                    'internationalMarket',
                    'internationalJointVenture',
                  ];
                  const formValuesEntries = Object.entries(formData.values);
                  const formattedFormData = Object.fromEntries(
                    formValuesEntries.map((x) => {
                      const key = x[0];
                      const value = x[1];
                      const isFile = fileFieldNameArray.includes(key);
                      const newValue = (() => {
                        if (isFile) return !!value[0] ? value[0].fileId : '';
                        return value;
                      })();
                      return [key, newValue];
                    }),
                  );
                  recommendMemberApplyMutation.mutate(formattedFormData);
                }}
                disabled={!isFillComplete}
                color="primary"
              >
                {t('common.sendForm')}
              </Button>
            </MessageBlock>
          )}
        </FormFooter>
      )}
      {!recommendMemberApplyMutation.isSuccess && isTablet_S && (
        <FormFooter>
          <Button onClick={next} fullWidth>
            {t('common.nextStep')}
          </Button>
        </FormFooter>
      )}
    </CameraLayout>
  );
}

export default RecommendMemberApplicationPage;
