import { useState, useContext } from 'react';
import { TaiccaContext } from 'App';
import styled, { css } from 'styled-components/macro';
import logoUrl from 'asset/logo_simple.png';
import logoRwdUrl from 'asset/logo_rwd.png';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { deleteFromStorage, writeStorage } from '@rehooks/local-storage';
import storageKey from 'constant/storageKey';
import MenuBurger from 'component/MenuBurger';
import { useMedia } from 'react-use';
import useUser from 'hook/useUser';
import { Divider } from 'component/Divider';

const Container = styled.div`
  display: flex;
  position: fixed;
  inset: 0 0 auto 0;
  width: 100%;
  height: 80px;
  padding: 0 30px;
  background: ${(props) => props.theme.color.bluegray900};
  border-bottom: solid 1px ${(props) => props.theme.color.bluegray000};
  z-index: 5;
  ${(props) => props.theme.media.tablet_S} {
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 30px;
  }
`;
const LogoWrapper = styled.div`
  flex: none;
  width: 174px;
  padding: 24px 0;
  ${(props) => props.theme.media.tablet_L} {
    padding: 24px 0;
  }
  ${(props) => props.theme.media.tablet_S} {
    padding: 24px 0;
    width: 200px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: auto;
  gap: 32px;
  padding-left: 30px;
  ${(props) => props.theme.media.tablet_L} {
    gap: 20px;
  }
`;
const RwdLinkContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${(props) => (props.isActive ? '100vh' : '0px')};
  background: ${(props) =>
    props.isActive
      ? 'rgba(255, 255, 255, 0.9)'
      : props.theme.color.bluegray900};
  backdrop-filter: ${(props) => (props.isActive ? 'blur(20px)' : 'blur(0px)')};
  -webkit-backdrop-filter: ${(props) =>
    props.isActive ? 'blur(20px)' : 'blur(0px)'};
  transform: translateZ(0);
  z-index: 98;
  transition: 0.8s;
  overflow: hidden;

  ${(props) =>
    props.isActive &&
    css`
      ${props.theme.media.tablet_S} {
        padding: 73px 70px;
      }
      ${props.theme.media.mobile_M} {
        padding: 73px 45px;
      }
      ${props.theme.media.mobile_S} {
        padding: 73px 25px;
      }
    `}
`;
const RwdMenuLinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const RwdMenuLink = styled(Link)`
  color: ${(props) => props.theme.color.bluegray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  color: ${(props) => props.isActive && props.theme.color.primary600};
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.primary600};
  }
`;
const MenuLink = styled(Link)`
  color: ${(props) => props.theme.color.bluegray300};
  font-size: 16px;
  text-decoration: none;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  &:hover {
    color: ${(props) => props.theme.color.bluegray000};
  }
`;
const Action = styled.div`
  color: ${(props) =>
    props.isActive
      ? props.theme.color.bluegray800
      : props.theme.color.bluegray300};
  cursor: pointer;
  font-size: ${(props) => (props.isActive ? '18px' : '16px')};
  font-weight: ${(props) => (props.isActive ? '500' : '400')};
  transition: 0.2s;
  &:hover {
    color: ${(props) => props.theme.color.bluegray000};
  }
  ${(props) => props.theme.media.tablet_S} {
    &:hover {
      color: ${(props) => props.theme.color.primary600};
    }
  }
`;

function Menu() {
  const { t } = useTranslation();
  const user = useUser();
  const setLocale = () => {
    const targetLocale = i18n.language === 'en-US' ? 'zh-TW' : 'en-US';
    i18n.changeLanguage(targetLocale);
    writeStorage(storageKey.LOCALE, targetLocale);
  };
  const isLogin = !!user;
  const isTablet_S = useMedia('(max-width: 551px)');
  const [isRwd, setIsRwd] = useState(false);
  const { nowTabs } = useContext(TaiccaContext);
  return (
    <Container>
      <LogoWrapper>
        <Link to="/">
          {isTablet_S ? (
            <img src={logoRwdUrl} alt="Logo" />
          ) : (
            <img src={logoUrl} alt="Logo" />
          )}
        </Link>
      </LogoWrapper>
      {!isTablet_S && (
        <LinkContainer>
          <MenuLink as="a" href="https://taicca.tw/">
            {t('menu.goFrontPage')}
          </MenuLink>
          <MenuLink
            as="a"
            target="_blank"
            href="https://taicca.tw/page/contact"
            rel="noreferrer noopener"
          >
            {t('menu.contactUs')}
          </MenuLink>
          {isLogin && (
            <Action
              onClick={() => {
                deleteFromStorage(storageKey.USER);
              }}
            >
              {t('menu.signOut')}
            </Action>
          )}
          <Action onClick={setLocale}>{t('locale')}</Action>
        </LinkContainer>
      )}
      {isTablet_S && (
        <>
          <MenuBurger
            isActive={isRwd}
            onClick={() => {
              if (isRwd) {
                setIsRwd(false);
                return;
              }
              setIsRwd(true);
              return;
            }}
          />
          <RwdLinkContainer isActive={isRwd}>
            {nowTabs && (
              <>
                <RwdMenuLinkGroup>
                  {nowTabs.tabOptions.map((tab) => (
                    <RwdMenuLink
                      isActive={nowTabs.selectedTab === tab.value}
                      key={tab.value}
                      as="div"
                      onClick={() => {
                        nowTabs.setSelectedTab(tab.value);
                        setIsRwd(false);
                      }}
                    >
                      {tab.label}
                    </RwdMenuLink>
                  ))}
                </RwdMenuLinkGroup>
                <Divider />
              </>
            )}
            <RwdMenuLinkGroup>
              <RwdMenuLink as="a" href="https://taicca.tw/">
                {t('menu.goFrontPage')}
              </RwdMenuLink>
              <RwdMenuLink
                as="a"
                target="_blank"
                href="https://taicca.tw/page/contact"
                rel="noreferrer noopener"
              >
                {t('menu.contactUs')}
              </RwdMenuLink>
              {isLogin && (
                <Action
                  isActive={isRwd}
                  onClick={() => {
                    deleteFromStorage(storageKey.USER);
                  }}
                >
                  {t('menu.signOut')}
                </Action>
              )}
              <Action isActive={isRwd} onClick={setLocale}>
                {t('locale')}
              </Action>
            </RwdMenuLinkGroup>
          </RwdLinkContainer>
        </>
      )}
    </Container>
  );
}

export { Menu };
