import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import storageKey from 'constant/storageKey';
import { deleteFromStorage } from '@rehooks/local-storage';

function LogoutPage() {
  useEffect(() => {
    deleteFromStorage(storageKey.USER);
  }, []);
  return <Redirect to="/" />;
}

export default LogoutPage;
