import styled, { css } from 'styled-components/macro';
import sendSchemeArrowUrl from 'asset/send_scheme_arrow.png';

const StyleSendSchemeItem = styled.div`
  position: relative;
  margin: 0 20px;
  padding: 32px 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  color: ${(props) =>
    props.isChoose
      ? props.theme.color.primary500
      : props.theme.color.bluegray900};
  background: ${(props) => props.theme.color.bluegray000};
  transition: 0.19s;
  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: auto 11px 11px auto;
    width: 28px;
    height: 10px;
    background: url(${sendSchemeArrowUrl}) no-repeat center / contain;
    transition: 0.19s;
    ${(props) =>
      props.isChoose
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  }
  transition: 0.19s;
  &:last-child {
    margin-bottom: 32px;
  }
  ${(props) => props.theme.media.laptop_M} {
    font-size: 20px;
  }
  ${(props) => props.theme.media.tablet_L} {
    margin: 0 15px;
    padding: 18px 5px;
    font-size: 16px;
  }
`;

const SendSchemeItem = ({
  onClick: onClickFunction,
  title,
  pathValue,
  children,
  isChoose,
  ...props
}) => {
  return (
    <StyleSendSchemeItem
      isChoose={isChoose}
      onClick={() => {
        if (onClickFunction) onClickFunction(pathValue);
      }}
    >
      {title}
    </StyleSendSchemeItem>
  );
};

export default SendSchemeItem;
