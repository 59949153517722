import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { useEffect, createContext, useState } from 'react';
import AppRoute from 'route/AppRoute';
import { HashRouter } from 'react-router-dom';
import cssReset from 'styled-reset';
import color from 'constant/color';
import media from 'constant/media';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import api from 'api';
import useUser from 'hook/useUser';
import { HelmetProvider } from 'react-helmet-async';
import { useLocalStorage } from '@rehooks/local-storage';
import storageKey from 'constant/storageKey';
import './fontawesome';
import './i18next';

const GlobalStyle = createGlobalStyle`
  ${cssReset}
  * {
    box-sizing: border-box;
  }
  body {
    background-color: ${color.bluegray900};
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    &,input,textarea,select {
      font-family: "PingFang TC", "Microsoft JhengHei", sans-serif;
      font-size: 16px;
    }
    input,textarea,select {
      &::placeholder {
        color: ${color.bluegray300};
      }
    }
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //     staleTime: 5 * 60 * 1000,
      //     cacheTime: 5 * 60 * 1000,

      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
const InfinityCacheOptions = {
  staleTime: Infinity,
  cacheTime: Infinity,
};
export const TaiccaContext = createContext();
function App() {
  const user = useUser();
  const isLogin = !!user;
  const [locale] = useLocalStorage(storageKey.LOCALE);
  const [isRwdMenuOpen, setIsRwdMenuOpen] = useState(false);
  const [nowTabs, setNowTabs] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [hasThroughRecommendApply, setHasThroughRecommendApply] =
    useState(false);
  const [disableApply, setDisableApply] = useState(true);

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);
  useEffect(() => {
    queryClient.prefetchQuery('city', api.getCityList, InfinityCacheOptions);
  }, []);
  useEffect(() => {
    if (!isLogin) return;
    const { verified } = user;
    queryClient.prefetchQuery('userInfo', api.userInfo, InfinityCacheOptions);
    queryClient.prefetchQuery(['projectList', 1], api.projectList, {
      enabled: verified,
    });
  }, [user, isLogin]);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          theme={{
            color,
            media,
            lang: { zh: ':lang(zh-TW)', en: ':lang(en-US)' },
            widthBoundary: '1440px',
            widthBoundary2: '1320px',
          }}
        >
          <HashRouter>
            <GlobalStyle />
            <TaiccaContext.Provider
              value={{
                isRwdMenuOpen,
                setIsRwdMenuOpen,
                nowTabs,
                setNowTabs,
                tabIndex,
                setTabIndex,
                hasThroughRecommendApply,
                setHasThroughRecommendApply,
                disableApply,
                setDisableApply,
              }}
            >
              <AppRoute />
            </TaiccaContext.Provider>
          </HashRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
