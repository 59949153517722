import { FormHeading } from 'component/Typography';
import BlockHeading from 'component/BlockHeading';
import Table from 'component/Table';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import api from 'api';
import { useMemo } from 'react';
import storageKey from 'constant/storageKey';

import dayjs from 'dayjs';

const formatTel = (area = '', body = '', extension = '') => {
  let number = '';
  if (area) number += `${area}-`;
  number += `${body}`;
  number += ` ${extension}`;
  return number;
};
function Flow3() {
  const formik = useFormikContext();
  const { values } = formik;
  const locale = localStorage.getItem(storageKey.LOCALE);

  const {
    applicant,
    email,
    password,
    contactAddress,
    tax,
    creationDate,
    numberOfEmployees,
    capital,
    registrantAddress,
    companyIntroduction,
    personInCharge,
    fax,
    contactPerson,
  } = values;
  const cityQuery = useQuery('city', api.getCityList);
  const contactCity = useMemo(() => {
    if (!cityQuery.isSuccess) return '';
    if (!values.contactAddress) return '';
    if (!values.contactAddress.city) return '';
    const cityKey = values.contactAddress.city;
    if (locale === 'en-US') return '';
    return cityQuery.data[cityKey].name;
  }, [locale, cityQuery, values.contactAddress]);
  const contactTown = useMemo(() => {
    if (!cityQuery.isSuccess) return '';
    if (!values.contactAddress) return '';
    if (!values.contactAddress.city) return '';
    if (!values.contactAddress.villagesAndTowns) return '';
    const cityKey = values.contactAddress.city;
    if (locale === 'en-US') return '';
    const townList = cityQuery.data[cityKey].dist;
    return townList.find(
      (town) => town.c3 === values.contactAddress.villagesAndTowns,
    ).name;
  }, [locale, cityQuery, values.contactAddress]);

  const registrantCity = useMemo(() => {
    if (!cityQuery.isSuccess) return '';
    if (!values.registrantAddress) return '';
    if (!values.registrantAddress.city) return '';
    const cityKey = values.registrantAddress.city;
    if (locale === 'en-US') return '';
    return cityQuery.data[cityKey].name;
  }, [locale, cityQuery, values.registrantAddress]);
  const registrantTown = useMemo(() => {
    if (!cityQuery.isSuccess) return '';
    if (!values.registrantAddress) return '';
    if (!values.registrantAddress.city) return '';
    if (!values.registrantAddress.villagesAndTowns) return '';
    const cityKey = values.registrantAddress.city;
    if (locale === 'en-US') return '';
    const townList = cityQuery.data[cityKey].dist;
    return townList.find(
      (town) => town.c3 === values.registrantAddress.villagesAndTowns,
    ).name;
  }, [locale, cityQuery, values.registrantAddress]);

  const { t } = useTranslation();
  const isMobileL = useMedia('(max-width: 425px)');
  return (
    <>
      <FormHeading>{t('common.flow.docConfirmation')}</FormHeading>
      <BlockHeading>{t('common.flow.companyBasicInfo')}</BlockHeading>
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: isMobileL ? '100px' : '192px',
          },
          {
            dataIndex: 'content',
            width: '100%',
          },
        ]}
        dataSource={[
          {
            name: t('common.flow.loginMailbox'),
            content: email,
          },
          {
            name: t('common.flow.password'),
            content: '*'.repeat(password.length),
          },
          { name: t('common.flow.applicant'), content: applicant },
          { name: t('common.flow.guiNumber'), content: tax },
          {
            name: t('common.flow.capital'),
            content: `${capital}`,
          },
          {
            name: t('common.flow.numberOfEmployees'),
            content: `${numberOfEmployees}`,
          },
          {
            name: t('common.flow.established'),
            content: !!creationDate
              ? dayjs(creationDate).format('YYYY.MM.DD')
              : '',
          },
          {
            name: t('common.flow.companyProfile'),
            content: companyIntroduction,
          },
          {
            name: t('common.flow.registeredAddress'),
            content: `${registrantCity}${registrantTown}${registrantAddress.street}`,
          },
        ]}
      />
      <BlockHeading>{t('common.flow.contactInfo')}</BlockHeading>
      <Table
        columns={[
          {
            dataIndex: 'name',
            width: isMobileL ? '100px' : '192px',
          },
          {
            dataIndex: 'content',
            width: '100%',
          },
        ]}
        dataSource={[
          {
            name: t('common.flow.personInChargeName'),
            content: personInCharge.name,
          },
          {
            name: t('common.flow.personInChargeTelephone'),
            content: formatTel(
              personInCharge.tel.area,
              personInCharge.tel.body,
              personInCharge.tel.extension,
            ),
          },
          {
            name: t('common.flow.personInChargePhone'),
            content: personInCharge.phone,
          },
          {
            name: t('common.flow.personInChargeEmail'),
            content: personInCharge.email,
          },
          {
            name: t('common.flow.fax'),
            content: formatTel(fax.area, fax.body, fax.extension),
          },
          {
            name: t('common.flow.contactAddress'),
            content: `${contactCity}${contactTown}${contactAddress.street}`,
          },
          {
            name: t('common.flow.contactPersonName'),
            content: contactPerson.name,
          },
          {
            name: t('common.flow.contactDepartment'),
            content: contactPerson.department,
          },
          {
            name: t('common.flow.contactDepartmentTelephone'),
            content: formatTel(
              contactPerson.tel.area,
              contactPerson.tel.body,
              contactPerson.tel.extension,
            ),
          },
          {
            name: t('common.flow.contactDepartmentPhone'),
            content: contactPerson.phone,
          },
          {
            name: t('common.flow.contactDepartmentEmail'),
            content: contactPerson.email,
          },
        ]}
      />
    </>
  );
}

export { Flow3 };
