import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Error } from 'component/Typography';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';

const successWrapperStyle = css`
  background: ${(props) => props.theme.color.success};
`;

const reviewWrapperStyle = css`
  background: ${(props) => props.theme.color.primary300};
`;

const warningWrapperStyle = css`
  background: ${(props) => props.theme.color.warning};
`;

const failWrapperStyle = css`
  background: ${(props) => props.theme.color.primary600};
`;

const unsentWrapperStyle = css`
  background: ${(props) => props.theme.color.bluegray300};
`;

const wrapperStyle = {
  success: successWrapperStyle,
  inreview: reviewWrapperStyle,
  invalid_first: warningWrapperStyle,
  invalid: failWrapperStyle,
  draft: unsentWrapperStyle,
  not_allow: failWrapperStyle,
  ing: unsentWrapperStyle,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 20px 16px 36px;
  box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.color.bluegray200};
  border-radius: 2px;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 auto 0 0;
    width: 6px;
    ${(props) => wrapperStyle[props.type]};
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 16px 20px 16px 30px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 16px 20px 16px 25px;
  }
`;

const StatusGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const successStatusStyle = css`
  color: ${(props) => props.theme.color.bluegray000};
  background: ${(props) => props.theme.color.success};
`;

const reviewStatusStyle = css`
  color: ${(props) => props.theme.color.bluegray900};
  background: ${(props) => props.theme.color.primary300};
`;

const warningStatusStyle = css`
  color: ${(props) => props.theme.color.bluegray000};
  background: ${(props) => props.theme.color.warning};
`;

const failStatusStyle = css`
  color: ${(props) => props.theme.color.bluegray000};
  background: ${(props) => props.theme.color.primary600};
`;

const unsentStatusStyle = css`
  color: ${(props) => props.theme.color.bluegray000};
  background: ${(props) => props.theme.color.bluegray300};
`;

const statusStyle = {
  success: successStatusStyle,
  inreview: reviewStatusStyle,
  invalid_first: warningStatusStyle,
  invalid: failStatusStyle,
  draft: unsentStatusStyle,
  not_allow: failStatusStyle,
};

const Status = styled.span`
  display: inline-block;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 2px;
  ${(props) => statusStyle[props.type]};
  span {
    ${(props) => props.theme.media.laptop_M} {
      font-size: 14px;
    }
    ${(props) => props.theme.media.tablet_L} {
      font-size: 12px;
    }
  }
  ${(props) => props.theme.media.laptop_M} {
    padding: 2px 10px;
  }
  ${(props) => props.theme.media.tablet_L} {
    padding: 0px 10px;
  }
`;

const Statuses = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const successActionStyle = css``;

const reviewActionStyle = css`
  pointer-events: none;
  opacity: 0.18;
`;

const warningActionStyle = css``;

const failActionStyle = css``;

const unsentActionStyle = css``;

const actionStyle = {
  success: successActionStyle,
  inreview: reviewActionStyle,
  invalid_first: warningActionStyle,
  invalid: failActionStyle,
  draft: unsentActionStyle,
};

export const Action = styled.span`
  padding: 0;
  color: ${(props) => props.theme.color.bluegray600};
  font-size: 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    color: ${(props) => props.theme.color.bluegray900};
  }
  svg {
    margin-left: 8px;
  }
  ${(props) =>
    props.enabled ? actionStyle[props.type] : actionStyle['inreview']}
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 12px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 14px;
`;

const Title = styled.h3`
  margin: 10px 0;
  color: ${(props) => props.theme.color.bluegray900};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 16px;
  }
`;

const SchemeGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.media.tablet_M} {
    gap: 3px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Scheme = styled.span`
  color: ${(props) => props.theme.color.bluegray900};
  font-size: 15px;
  line-height: 21px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 13px;
  }
  ${(props) => props.theme.media.tablet_L} {
    font-size: 12px;
  }
`;

const Time = styled.span`
  color: ${(props) => props.theme.color.bluegray500};
  font-size: 14px;
  ${(props) => props.theme.media.laptop_M} {
    font-size: 12px;
  }
`;

function StatusText(props) {
  const { t } = useTranslation();
  const statusTextMap = {
    success: t('memberCenter.myRecord.success'),
    inreview: t('memberCenter.myRecord.inReview'),
    invalid_first: t('memberCenter.myRecord.invalidFirst'),
    invalid: t('memberCenter.myRecord.invalid'),
    draft: t('memberCenter.myRecord.draft'),
    not_allow: t('memberCenter.myRecord.notAllow'),
  };
  const statusText = statusTextMap[props.type];

  return <span>{statusText}</span>;
}

function ActionIcon(props) {
  const ActionIconMap = {
    success: <FontAwesomeIcon icon={['fas', 'download']} />,
    inreview: <FontAwesomeIcon icon={['fas', 'pen']} />,
    invalid_first: <FontAwesomeIcon icon={['fas', 'pen']} />,
    invalid: <FontAwesomeIcon icon={['fas', 'pen']} />,
    draft: <FontAwesomeIcon icon={['fas', 'pen']} />,
  };
  const actionIcon = ActionIconMap[props.type];

  return <>{actionIcon}</>;
}

function ActionText(props) {
  const { t } = useTranslation();
  const ActionTextMap = {
    success: t('memberCenter.myRecord.download'),
    inreview: t('memberCenter.myRecord.edit'),
    invalid_first: t('memberCenter.myRecord.editReview'),
    invalid: t('memberCenter.myRecord.editReview'),
    draft: t('memberCenter.myRecord.edit'),
  };
  const actionText = ActionTextMap[props.type];

  return <span>{actionText}</span>;
}

const SendCard = ({
  dataList,
  type,
  enabled,
  id,
  title,
  scheme,
  time,
  url,
  remarks,
  plan,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectDeleteMutation = useMutation(api.projectDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['projectList', 1]);
    },
  });
  return (
    <Wrapper type={type}>
      <StatusGroup>
        <Statuses>
          <Status type={type}>
            <StatusText type={type}>{plan}</StatusText>
          </Status>
          {type === 'invalid_first' && <Error>{remarks}</Error>}
        </Statuses>
        <Actions>
          {type === 'success' ? (
            <Action
              as={'a'}
              type={type}
              enabled={enabled}
              target="_blank"
              href={url}
            >
              <ActionText type={type}></ActionText>
              <ActionIcon type={type}></ActionIcon>
            </Action>
          ) : (
            <Action as={Link} type={type} enabled={enabled} to={url}>
              <ActionText type={type}></ActionText>
              <ActionIcon type={type}></ActionIcon>
            </Action>
          )}
          {type === 'draft' && (
            <Action
              type={type}
              enabled={enabled}
              onClick={() => {
                if (window.confirm(t('memberCenter.myRecord.confirmDelete'))) {
                  projectDeleteMutation.mutate(id);
                }
              }}
            >
              <span>{t('memberCenter.myRecord.delete')}</span>
              <FontAwesomeIcon icon={['fas', 'trash']} />
            </Action>
          )}
        </Actions>
      </StatusGroup>
      <Title>{title}</Title>
      <SchemeGroup>
        <Scheme>{scheme}</Scheme>
        <Time>{time}</Time>
      </SchemeGroup>
    </Wrapper>
  );
};

SendCard.defaultProps = {
  type: '',
  title: '',
  scheme: '',
  time: '',
};

export default SendCard;
