import { library } from '@fortawesome/fontawesome-svg-core';
//  fal
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';
//  far
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
//  fas
import {
  faEyeSlash,
  faEye,
  faCalendarDay,
  faPen,
  faDownload,
  faSortDown,
  faExclamation,
  faCheck,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faExclamationCircle,
  faUpload,
  faCloudUpload,
  faSortUp,
} from '@fortawesome/pro-solid-svg-icons';

// usage:
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// <FontAwesomeIcon icon={['fal', 'minus']} />

const bizformIcons = [
  faPlus,
  faMinus,
  faEyeSlash,
  faEye,
  faCalendarDay,
  faTimes,
  faPen,
  faDownload,
  faSortDown,
  faExclamation,
  faCheck,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faExclamationCircle,
  faUpload,
  faCloudUpload,
  faSortUp,
];
library.add(...bizformIcons);
