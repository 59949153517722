import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: auto;
  font-size: 16px;
  line-height: 22px;

  * {
    border: 0;
    box-sizing: border-box;
  }
`;
const Th = styled.th`
  width: ${(props) => props.$width};
  padding: 8px 0;
`;

const Td = styled.td`
  width: ${(props) => props.$width};
  padding: 8px 0;
  background: #fff;
  white-space: pre-line;
  &:empty {
    padding: 0;
  }
  ${(props) => props.theme.media.laptop_M} {
    font-size: 14px;
  }
  ${(props) => props.theme.media.mobile_M} {
    font-size: 12px;
  }
`;

const Tr = styled.tr`
  ${(props) =>
    props.$hidden &&
    css`
      display: none;
    `};
`;

/**
 * `Table` 顧名思義就是一個表格元件，用來整齊的顯示行列數據。
 */
const Table = ({ columns, dataSource, ...props }) => {
  const hasTitle = columns.some((column) => !!column.title);
  return (
    <StyledTable $columnsCount={columns.length} {...props}>
      {hasTitle && (
        <thead>
          <Tr>
            {columns.map((column) => (
              <Th key={column.dataIndex} $width={column.width}>
                {column.title}
              </Th>
            ))}
          </Tr>
        </thead>
      )}
      <tbody>
        {dataSource.map((data, i) => (
          <Tr key={i}>
            {columns.map((column) => {
              const { dataIndex, width } = column;
              const foundCellData = column.render
                ? column.render(data[dataIndex])
                : data[dataIndex];
              return (
                <Td $width={width} key={dataIndex}>
                  {foundCellData}
                </Td>
              );
            })}
          </Tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

Table.defaultProps = {
  columns: [],
  dataSource: [],
};

export default Table;
